import React from 'react'
import { observer } from 'mobx-react'

import * as RS from 'reactstrap'

import { Label } from 'sdc-i18n-react'


export const EntryTab = observer(({onSelect,activeTab,tabId}) => <RS.NavItem>
  <RS.NavLink onClick={onSelect(tabId)} className={activeTab === tabId ? 'active' : ''}>
    <Label value={'tab.header.'+tabId} />
  </RS.NavLink>
</RS.NavItem>)
