import React from 'react'

import { Label } from 'sdc-i18n-react'

import CreateProjectDialog   from './create/CreateProjectDialog'
import { SettingsDialog } from './settings/dialog'

import { ContentPanel } from '../../components'
import { ProjectList  } from './list'
import { BookAd       } from 'sdc-amazon-ads'


export const ProjectsPanel = () => <ContentPanel>
  <h4><Label value="projects.header" /></h4>
  <BookAd slot="projects" />
  <CreateProjectDialog />
  <SettingsDialog />
  <ProjectList />
</ContentPanel>
