import { action, observable } from 'mobx'

export class DetailsState {

  @observable activeTab = 'general'

  selectTab = tab => action(() => {
    this.activeTab = tab
  })

}

export const detailsState = new DetailsState()
