import React from 'react'
import { inject, observer } from 'mobx-react'

import { Link, NavLink } from 'react-router-dom'

import { Label } from 'sdc-i18n-react'

import { LoginButton, LogoutButton, VisibleOnlyAuthenticated, UserName } from 'sdc-auth-react'
import { PomodoroTime, pomodoroState } from 'sdc-pomodoro'

import { ProjectName     } from '../authoring/projects/ProjectName'

import { AppVersion      } from 'sdc-react-basics'
import { LanguagesMenu   } from 'sdc-i18n-react'

import { uiBase          } from '../env/constants'
import { loginURL        } from '../user/login/urls'
import { projectsURL     } from '../authoring/projects/urls'
import { progressURL     } from '../authoring/progress/urls'
import { valuesURL       } from '../authoring/values/urls'
import { dashURL         } from '../authoring/dashboard/urls'
import { snowURL         } from '../authoring/snowflake/urls'
import { boardURL        } from '../authoring/story/urls'

import { booksURL        } from '../backoffice/books/urls'
import { usersURL        } from '../backoffice/users/urls'
import { genresURL       } from '../backoffice/genres/urls'

import { DynamicNavLink  } from './DynamicNavLink'
import { ProjectNavLink  } from './ProjectNavLink'

import './NavBar.scss'

const WritingLink = VisibleOnlyAuthenticated(() =>
  <li className="nav-item"><NavLink className="nav-link" to={projectsURL}>
    <i className="fa fa-edit"></i> <Label value="nav.projects" />
  </NavLink></li>
)

export const NavBar = inject('uiStore','userStore','projectsStore','currentStore')(observer(
({uiStore,userStore,projectsStore,currentStore}) =>
  <nav className="navbar navbar-expand-lg navbar-light navbar-gmcd navbar-static-top">

      <div className="navbar-header">
        <Link className="navbar-brand" to={uiBase}>
          <span><img src="favicon32.png" alt="" /></span>
          <b style={{paddingLeft: '4px', fontSize: '1.4rem'}}>GMCD</b>
        </Link>
      </div>

        <ul className="nav navbar-nav mr-auto">
          <DynamicNavLink show={userStore.user.name} label="nav.snowflake"  icon="snowflake-o" to={snowURL}     />
          <WritingLink />
          <li className="nav-item"><ProjectName /></li>
          <ProjectNavLink label="nav.dashboard"  icon="tasks"       to={dashURL}     />
          <ProjectNavLink label="nav.storyboard" icon="th"          to={boardURL}    />
          <ProjectNavLink label="nav.progress"   icon="line-chart"  to={progressURL} />
          <ProjectNavLink label="nav.values"     icon="money"       to={valuesURL}   />
          {pomodoroState.running && <li className="nav-item sdc-pomodoro-panel">
            <PomodoroTime value={pomodoroState.current.timeLeft} ticks={pomodoroState.current.ticks} className={'pomodoro-time '+pomodoroState.mode} blink />
          </li>}
        </ul>

        <AppVersion version={process.env.REACT_APP_VERSION} />

        <ul className="nav navbar-nav">
          <DynamicNavLink show={userStore.user.admin} label="nav.genres" icon="book"           to={genresURL} />
          <DynamicNavLink show={userStore.user.admin} label="nav.users"  icon="users"          to={usersURL} />
          <DynamicNavLink show={userStore.user.admin} label="nav.ads"    icon="address-card-o" to={booksURL} />
          <li className="nav-item"><NavLink className="nav-link" to={usersURL}><UserName /></NavLink></li>
          <LanguagesMenu nav />
          <li className="nav-item"><a className="nav-link" href="https://docs.gmcd.io" target="_new"><i className="fa fa-question-circle-o" /> <Label value="sites.docs" /></a></li>
          <li className="nav-item"><LoginButton url={loginURL} /></li>
          <li className="nav-item"><LogoutButton /></li>
        </ul>

  </nav>
))
