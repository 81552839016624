import { autorun } from 'mobx'

import { ContentApi } from 'sdc-cms-client'
import FilteringStore from '../../../data/FilteringStore'

export const typeID = 'cQdY3GMhanZD2bVJNMP2qEDK2f9wlN2y'


export class TodosStore extends FilteringStore {

  constructor({seriesStore,storiesStore,...options}) {
    super({
      ...options,
      typeID,
    })
    autorun(() => {
      this.setFilter(
        this.worldsStore.selected.id,
        seriesStore.selected.id,
        storiesStore.selected.id,
      )
    })
  }

}

export default ({backend,...options}) => new TodosStore({...options,api:ContentApi({backend,typeID})})
