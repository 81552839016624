import React from 'react'
import { inject, observer } from 'mobx-react'

import { ValueTextEdit } from 'sdc-cms-editing'

const ParentSeries = inject('seriesStore')(observer(({seriesStore}) =>
  <ValueTextEdit field="series"  choices={seriesStore.dataList} />
))

export default ParentSeries
