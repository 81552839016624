import React from 'react'
import { inject, observer } from 'mobx-react'

import './name.scss'



export const ProjectName = inject('projectsStore')(observer(({projectsStore}) => projectsStore.selected.id ?
<a className='gmcd-project-name nav-link active'>{projectsStore.selected.title}</a>
: null))
