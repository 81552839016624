import { autorun, action, observable } from 'mobx'

import * as api from './api'

import projectsStore from '../projects/store'

import { ReloadingStore } from 'sdc-mobx-stores'
import { subscribe      } from 'sdc-publish-subscribe'


import { TOPIC_PROJECT_DATA_LOADED } from '../topics'

export class HistoricStore extends ReloadingStore {

  @observable state = {}
  @observable sections = []

  currentProject = null

  constructor(api) {
    super({
      listName : "days",
      entryName: "project",
      api,
    })

    subscribe(TOPIC_PROJECT_DATA_LOADED, this.reload)

    autorun(() => {
      if (projectsStore.selected.id) {
        if (this.currentProject !== projectsStore.selected.id) {
          this.currentProject = projectsStore.selected.id
          this.clear()
          this.reload(projectsStore.selected.id)
          this.setupReload({
            action    : this.reload,
            onClear   : this.clearEntries,
            autostart : false,
          })
        }
      } else {
        this.clearReload()
      }
    })
  }

  @action
  clear = () => {
    this.state = {}
  }

  reload = projectCount => {

    if (!projectCount) return;
    if (!projectsStore.selected.id) return;

    this.list({
      params   : projectsStore.selected.id,
      callback : this.parseData,
    })()
  }
  parseData = deferred => action(payload => {
    if (!this.state.lastModified || this.state.lastModified < payload.lastModified) {
      this.setEntries(deferred)(payload)
      this.sections = payload.sections || []
      this.state = {
        sequence    : payload.sequence,
        lastModified: payload.lastModified,
      }
    }
  })

}

export default new HistoricStore(api)
