const baseChart = {
  x: d => d.x,
  y: d => d.y,
  forceX: [0,1],
  forceY: [-5,10],
  interactive: false,
}
const lineChart = {
  ...baseChart,
  type: 'lineChart',
}
const scatterChart = {
  ...baseChart,
  type: 'scatterChart',
}

const xAxisIndex = {
  xAxis : {
    axisLabel  : 'Scene',
    tickFormat : d => d,
  },
}
const yAxisValue = {
  yAxis : {
    axisLabel: 'Value'
  }
}

export const graph = {
  ...scatterChart,
  ...xAxisIndex,
  ...yAxisValue,
}
