import moment from 'moment'

export const cutoffSince = (num,unit) => data => {
  const cutoff = moment().subtract(num,unit)
  return data.filter(d => moment(d.day, 'YYYY-MM-DD').isAfter(cutoff))
}

export const has = field => d => d[field]

export const toSeries = (field,cutter,mapper) => data =>
  cutter((data || []).filter(has(field))).map(mapper)

export const toPoint = (field = 'words', factor = 1) => entry => ({
  x: moment(entry.day, 'YYYY-MM-DD'),
  y: factor * entry[field],
})
