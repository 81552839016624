import React from 'react'

import { Container, Row, Col } from 'reactstrap'

import { ContentPanel        } from '../../components'
import { TypePanel           } from '../../components/entries/TypePanel'


import { GenresList          } from './list'
import { GenreDetails        } from './details'

import { FormatsList         } from '../formats/list'
import { FormatDetails       } from '../formats/details'

export const GenresPanel = props =>
<ContentPanel>
  <Container fluid>
    <Row>
      <Col>
        <TypePanel type="genres" visible alwaysOpen {...props} >
          <GenresList />
          <GenreDetails />
        </TypePanel>
      </Col>
      <Col>
        <TypePanel type="formats" visible alwaysOpen {...props} >
          <FormatsList />
          <FormatDetails />
        </TypePanel>
      </Col>
    </Row>
  </Container>
</ContentPanel>
