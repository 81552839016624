import { action, autorun, observable } from 'mobx'
import _ from 'lodash'

import { ContentApi   } from 'sdc-cms-client'
import { UpdatingStore, editingMode, editingType }  from 'sdc-cms-writing'

import { worldsType   } from '../../../env/constants'


export class WorldsStore extends UpdatingStore {

  @observable worlds = {}

  constructor({indexStore,...options}) {
    super({
      typeID : worldsType,
      ...options,
    })
    this.indexStore = indexStore
    this.reload()
    autorun(() => {
      if (editingType.typeID === this.typeID) {
        this.typesStore.selectType(this.typeID)
        this.editingStore.updateListener = this
        this.editingStore.selectField({})()
        if (editingMode.isViewMode.get()) {
          this.clearSelected()
        }
      }
    })
  }

  afterSelect = () => {
    editingType.clear()
    this.typesStore.selectType(this.typeID)
    this.indexStore.setParentID(this.selected.id)
    this.editingStore.updateListener = this
    this.editingStore.setSelected(this.selected)
    this.editingStore.selectField({})()
    setTimeout(() => {
      editingType.setTypeID(this.typeID)
    },1)
  }

  afterCreate = entry => {
    entry.values = {}
    this.setSelected(entry)
    this.typesStore.selectType(this.typeID)
    this.editingStore.setBuffers(entry)
    console.log(entry)
  }

  reload = () => {
    this.list({callback:this.parseWorlds})()
  }

  parseWorlds = deferred => action(payload => {
    this.worlds = _.keyBy(payload.content, 'id')
    this.setEntries(deferred)(payload)
  })

  name = id => id ? ((this.worlds[id] || {}).values || {}).name : '--'

}

export const makeWorldsStore = ({backend,...options}) => new WorldsStore({...options,api:ContentApi({backend,typeID:worldsType})})
