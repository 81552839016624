import   React       from 'react'
import { Label                      } from 'sdc-i18n-react'
import { languagesStore             } from 'sdc-i18n-stores'

import { CreateButton, DeleteButton } from 'sdc-react-basics'

import { dateCell, wordCount        } from './cells'
import { WordCount                  } from '../components/manuscript/WordCount'

export const date = ({caption,field}) => ({
  Header: <Label value={caption} />,
  accessor: field,
  className: 'centered',
  width: 100,
  resizable: false,
  Cell : dateCell,
})

export const wc = ({caption,field,total}) => ({
  Header: <Label value={caption} />,
  accessor: field,
  className: 'right pr-4',
  width: 100,
  resizable: false,
  Cell: wordCount,
  Footer  : <WordCount value={total} suffix="" />
})

export const wcc = ({caption,compute,total}) => ({
  Header: <Label value={caption} />,
  accessor: 'id',
  className: 'right pr-4',
  width: 100,
  resizable: false,
  Cell    : cell => <WordCount value={compute(cell.original)} suffix="" />,
  Footer  : <WordCount value={total} suffix="" />
})

export const createDelete = ({type,onCreate,onDelete,canDelete}) => ({
  Header: onCreate && <CreateButton onClick={onCreate} title={type+'.create'} />,
  accessor: 'id',
  headerClassName: 'centered',
  className: 'centered',
  width: 30,
  sortable: false,
  resizable: false,
  Cell : cell => (typeof canDelete !== 'function' || canDelete(cell)) && onDelete ?
    <DeleteButton onClick={onDelete(cell.original)} title={type+'.delete'} /> : null,
})

export const name = i18n => ({
  Header: <Label value="field.name" />,
  accessor: i18n ? 'values.name.'+languagesStore.selectedLanguage : 'values.name',
  headerClassName: 'left',
})

export const done = () => ({
  Header: '',
  accessor: 'values.done',
  headerClassName: 'centered',
  className: 'centered',
  width: 30,
  sortable: false,
  resizable: false,
  Cell : cell => cell.original.values.done ? <span><i className="fa fa-check-square-o"></i></span> : null,
})

export const globally = () => ({
  Header: '',
  accessor: 'values.globally',
  headerClassName: 'centered',
  className: 'centered',
  width: 30,
  sortable: false,
  resizable: false,
  Cell : cell => cell.original.values.globally ? <span><i className="fa fa-globe"></i></span> : null,
})
