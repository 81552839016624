import React       from 'react'
import { inject, observer } from 'mobx-react'

import { ContentPanel } from '../../components'

import BooksList        from './BooksList'
import CreateBookButton from './CreateBookButton'
import { BookCover    } from 'sdc-amazon-ads'


const initialState = {asin:''}

class BooksPanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }
  onAsinChange = (e) => {
    if (e.target.value.length === 10) {
      this.props.booksStore.create({
        values: {
          asin: e.target.value,
        },
      })()
      this.setState(initialState)
    } else if (this.state.asin !== e.target.value) {
      this.setState({
        asin: e.target.value
      })
    }
  }
  render() {
    return <ContentPanel className="container-fluid">
      <div className="row">
        <div className="col-md-1" style={{padding: '4px'}}>
          <form>
            <input className="form-control" type="text" placeholder="ASIN..."
              value={this.state.asin} onChange={this.onAsinChange} />
          </form>
          <BookCover asin={this.state.asin} />
          <CreateBookButton onClick={this.onAsinSubmit} />
        </div>
        <BooksList />
      </div>
    </ContentPanel>
  }
}

export default inject('booksStore')(observer(BooksPanel))
