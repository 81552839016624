import React from 'react'
import { toJS             } from 'mobx'
import { inject, observer } from 'mobx-react'

import { languagesStore   } from 'sdc-i18n-stores'
import { ValueTextEdit    } from 'sdc-cms-editing'


export const EntryFields = inject('typesStore','controlsStore')(observer(({typesStore,controlsStore,value,fields,...props}) => {
  if (value) {
    const extras = (controlsStore.valuesByKey[value] || {}).value
    fields = extras ? extras.split(',') : []
  }

  const locales = languagesStore.languagesAvailable.map(lang => toJS(languagesStore.languages[lang]))

  return <React.Fragment>
    {fields.map(field =>
      typesStore.fields[field].localize
      ? locales.map(locale => <ValueTextEdit key={`${field}:${locale.key}`} field={field} locale={locale} {...props} />)
      : <ValueTextEdit key={field} field={field} {...props} />
    )}
  </React.Fragment>
}))
