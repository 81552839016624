import d3 from 'd3'
import moment from 'moment'

export const baseChart = {
  x: d => d.x,
  y: d => d.y,
  forceY: [0,1],
  interactive: false,
}
export const lineChart = {
  ...baseChart,
  type: 'lineChart',
}
export const scatterChart = {
  ...baseChart,
  type: 'scatterChart',
}

export const xAxisDate = {
  xAxis : {
    axisLabel  : 'Date',
    tickFormat : d => moment(d).format('DD.MM.YY'),
  },
  xScale : d3.time.scale(),
}
export const xAxisTime = {
  xAxis : {
    axisLabel  : 'Time',
    tickFormat : d => moment(d).format('HH:mm'),
    ticks      : 8,
  },
  xScale : d3.time.scale(),
}
export const yAxisWords = {
  yAxis : {
    axisLabel: 'Words'
  }
}

export const book = {
  ...lineChart,
  ...xAxisDate,
  ...yAxisWords,
}
export const seqs = {
  ...lineChart,
  ...xAxisDate,
  ...yAxisWords,
}
export const words = {
  ...scatterChart,
  ...xAxisDate,
}
export const daily = {
  ...scatterChart,
  ...xAxisTime,
  useVoronoi: false,
}
export const hourly = {
  ...baseChart,
  type: 'multiBarChart',
  showControls: false,
  xAxis: {
      axisLabel: 'Hour',
      tickFormat: d => moment(d).format('HH:mm')
  },
  yAxis: {
      tickFormat: d => d3.format('.f')(d)
  },
}
export const progress = {
  type: 'bulletChart',
  height: 32,
  //tooltips: false
}
