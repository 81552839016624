import React from 'react'
import DebouncedInput from 'react-debounce-input'

export default ({project,updateProject}) => (
  <div className="step">
    <h1>Working Title and Shared Folder for your Project</h1>
    <p>First of all, we need to agree on a <b>working title</b> and a <b>shared folder</b> for your project.</p>
    <div className="alert alert-hint" style={{width: '50vw'}}>
      <i className="fa fa-info-circle"/> &nbsp; Please do not mistake the <b>working title</b> with your <b>story's title</b>.
      <ul>
        <li>You may change the working title at any time in the project settings.</li>
        <li>You may change the story's title at any time within Scrivener.</li>
      </ul>
    </div>
    <form style={{width: '50vw'}}>
      <label htmlFor="ms-title"><img src="/favicon24.png" alt="" />
          &nbsp; Working Title <span style={{fontWeight: 'normal'}}>to use with GMCD</span></label>
      <div className="input-group" style={{marginBottom: '12px'}}>
        <span className="input-group-addon"><i className="fa fa-edit" /></span>
        <DebouncedInput className="form-control" type="text" placeholder="Enter project title..."
            value={project.title}
            onChange={(e) => updateProject({title: e.target.value})}/>
      </div>
      <label htmlFor="ms-folder"><img src="/dropbox-logo.png" width="27" height="25" alt="" />
          &nbsp; Shared Folder <span style={{fontWeight: 'normal'}}>in your Dropbox</span></label>
      <div className="input-group" style={{marginBottom: '12px'}}>
        <span className="input-group-addon">/Manuscripts/</span>
        <input className="form-control" type="text" placeholder="Folder..." readOnly
            value={project.folder} />
      </div>
    </form>
  </div>
)
