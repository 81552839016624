import React from 'react'
import { inject, observer } from 'mobx-react'

import { ValueTextEdit } from 'sdc-cms-editing'

const ParentWorld = inject('worldsStore')(observer(({worldsStore}) =>
  <ValueTextEdit field="world"  choices={worldsStore.dataList} />
))

export default ParentWorld
