import React from 'react'

import NVD3Chart from 'react-nvd3'
import 'nvd3/build/nv.d3.css'

import * as options from '../progress/options'


const ProgressIndicator = ({count,target,scale}) => <NVD3Chart datum={{
  ranges: [1*scale,2*scale,3*scale,4*scale,5*scale],
  measures: [count],
  markers: [target]
}} {...options.progress} duration={scale / 10} />

export default ProgressIndicator
