import React from 'react'

import NumberFormat from 'react-number-format'

import { labelsStore } from 'sdc-i18n-stores'

import './style.scss'

export const PageCount = ({showZero,suffix=' '+labelsStore.labelFor('target.pages'),style={},...props}) => (props.value || showZero) ?
  <NumberFormat {...props} className="page-count" style={style}
    displayType='text' suffix={suffix} thousandSeparator=' ' decimalScale={0} /> : null
