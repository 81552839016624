import React from 'react'
import { inject, observer } from 'mobx-react'

import { Responsive, WidthProvider } from 'react-grid-layout'

import { Label         } from 'sdc-i18n-react'
import { MarkdownPanel } from 'sdc-markdown-viewer'

import './snowflake.scss'

const ResponsiveReactGridLayout = WidthProvider(Responsive)

const layout = [
  {i:'step-0', x:0, y:0, w:3, h:1 },
  {i:'step-1', x:3, y:0, w:3, h:1 },
  {i:'step-2', x:6, y:0, w:3, h:1 },
  {i:'step-3', x:9, y:0, w:3, h:1 },
  {i:'step-4', x:0, y:1, w:3, h:1 },
  {i:'step-5', x:3, y:1, w:3, h:1 },
  {i:'step-6', x:6, y:1, w:3, h:1 },
  {i:'step-7', x:9, y:1, w:3, h:1 },
  {i:'step-8', x:0, y:2, w:3, h:1 },
  {i:'step-9', x:3, y:2, w:3, h:1 },
  {i:'step-10',x:6, y:2, w:3, h:1 },
  {i:'step-99',x:9, y:2, w:3, h:1 },
]

const Step = inject('helpStore')(observer(({helpStore,step}) => <React.Fragment>
  <Label className="pull-right" as="h4" value="snowflake.step" step={step} />
  <h2>{helpStore.helpTitleFor('snowflake.step-'+step)}</h2>
  <MarkdownPanel ignoreIndex text={helpStore.helpTextFor('snowflake.step-'+step)} />
</React.Fragment>))

export class SnowflakePanel extends React.Component {
  constructor(props) {
    super(props);
    this.resize = this.resize.bind(this)
  }
  componentDidMount() {
    this.addResizeListener()
  }
  componentWillUnmount() {
    if (this.resizeHandler) {
      clearTimeout(this.resizeHandler)
    }
    this.removeResizeListener()
  }

  addResizeListener() {
    if (!this.resizeHandler) {
      if (window.addEventListener)
        window.addEventListener("resize", this.resize, false);
      else
        window.attachEvent("resize", this.resize, false);
    }
  }
  removeResizeListener() {
    if (window.removeEventListener)
      window.removeEventListener("resize", this.resize);
    else
      window.detachEvent("resize", this.resize);
  }
  resize(e,repeated) {
    clearTimeout(this.resizeHandler)
    this.resizeHandler = setTimeout(() => {
      clearTimeout(this.resizeHandler)
      this.forceUpdate()
      if (!repeated) {
        this.resizeHandler = setTimeout(() => {
          clearTimeout(this.resizeHandler)
          this.resize(e,true)
        }, 500)
      }
    }, 10)
  }

  render() {
    return <div>
      <ResponsiveReactGridLayout className="snowflake"
              breakpoints={{def:1200}} layouts={{def:layout}} cols={{def:12}}
              margin={[16,16]} isDraggable={false} isResizable={false}
              rowHeight={(window.innerHeight - 120) / 3} >
        {[0,1,2,3,4,5,6,7,8,9,10,99].map(step => <div key={'step-'+step} className={'step-'+step}><Step step={step} /></div>)}
      </ResponsiveReactGridLayout>
    </div>
  }

}
