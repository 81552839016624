import { action, observable, computed } from 'mobx'

export class UIStore {

  @observable details = 'high'
  @observable mode = 'edit'
  @observable type = null
  @observable showIndex = true
  @observable maximized = false
  @observable colWidth = 22.0

  setDetails = details => action(() => {
    this.details = details
  })
  isDetailsMode  = computed(() => this.details === 'high')
  isOverviewMode = computed(() => this.details === 'some')
  isBeatListMode = computed(() => this.details === 'none')
  isPlottingMode = computed(() => this.details === 'plot')

  setMode = mode => action(() => {
    this.mode = mode
  })
  isEditMode = computed(() => this.mode === 'edit')
  isViewMode = computed(() => this.mode === 'view')

  @action
  setType = type => {
    this.type = type
  }
  @action
  clear = () => {
    this.type = null
  }
  @action
  toggleIndex = () => {
    this.showIndex = !this.showIndex
    this.maximized &= this.showIndex
  }
  @action
  toggleMaximized = () => {
    this.maximized = !this.maximized
  }

  @action
  colWiden = () => {
    this.colWidth *= 1.25
  }
  @action
  colNarrow = () => {
    this.colWidth /= 1.25
  }
  @action
  colReset = () => {
    this.colWidth = 22.0
  }
}

export default new UIStore()
