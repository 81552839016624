import { backend } from '../../io/backend'

const baseUrl = 'projects'

export const create = (project) => () => {
  console.log('creating project:')
  console.log(project)
  return backend.post(baseUrl,project)
}

export const list   = ()      => () => backend.get(baseUrl)
export const remove = entry   => () => backend.delete(baseUrl+'/'+entry.id)

export const update = project => () => backend.put(baseUrl,project)
