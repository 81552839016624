import { action, autorun, observable } from 'mobx'
import compose from 'lodash/fp/compose'

import * as api from './api'

import { userStore } from 'sdc-auth-user'
import { ReloadingStore, Creating, Updating } from 'sdc-mobx-stores'
import { publish   } from 'sdc-publish-subscribe'

import { TOPIC_PROJECT_DATA_LOADED } from '../topics'

const Mixins = compose(
  Creating,
  Updating,
)

export class ProjectsStore extends Mixins(ReloadingStore) {

  @observable state = {
    creating: false,
    updating: false,
  }

  constructor(api) {
    super({
      listName : "list",
      entryName: "project",
      api,
      noClearAfterLoad: true,
    })
    autorun(() => {
      if (userStore.user.id) {
        this.setupReload({
          action : this.reload,
          onClear: this.clearEntries,
        })
      } else {
        this.clearReload()
      }
    })
  }

  reload = () => {
    this.list({
      callback : this.parseData,
    })()
  }

  parseData = deferred => payload => {
    this.setEntries(deferred)(payload)
    this.selectByID(this.selectedID)
    publish(TOPIC_PROJECT_DATA_LOADED, this.dataList.length)
  }

  selectByID = id => {
    /* eslint-disable eqeqeq */
    if (this.dataList.length && id && this.selected.id != id) {
      const byID = p => p.id == id
      /* eslint-enable eqeqeq */
      this.select(this.dataList.find(byID))()
    }
    this.selectedID = id
  }

  @action
  showCreateDialog = () => {
    this.state.creating = true
  }
  @action
  hideCreateDialog = () => {
    this.state.creating = false
  }

  @action
  showUpdateDialog = () => {
    this.state.updating = true
  }
  @action
  hideUpdateDialog = () => {
    this.state.updating = false
  }

}

export default new ProjectsStore(api)
