import React from 'react'
import { observer } from 'mobx-react'

import ReactTable from 'react-table'
import 'react-table/react-table.css'

import './DataTable.css'


@observer
export class DataTable extends React.Component {
  render() {
    const {store,style,scale,listName,sortable = false,...otherProps} = this.props
    const list = listName || 'dataList'
    const data = store[list].toJS()
    const filtered = (this.props.filter) ? data.filter(this.props.filter) : data
    const toDisplay = (this.props.onlyActive) ? filtered.filter(e => !(e.locked || false)) : filtered
    const selectedID = store.selected.id
    const scaledFontSize = (100*(scale || 1.0)) +'%'
    return (<ReactTable data={toDisplay}
      className='-highlight selectable'
      style={{minHeight: '70px', maxHeight:'400px', marginBottom: '12px', fontSize: scaledFontSize, lineHeight: '1em', ...style,}}
      pageSize={toDisplay.length}
      showPagination={false}
      sortable={sortable}
      defaultSorted={this.props.sorting || [{
        id : 'values.name',
      }]}
      getTrProps={(state,rowInfo) => {
        const locked   = (rowInfo && rowInfo.original && rowInfo.original.locked) ? 'locked ' : ''
        const selectedCls = (rowInfo && rowInfo.original && rowInfo.original.id === selectedID) ? 'selected' : ''
        return {
          className : locked + selectedCls,
          onClick : store.select(rowInfo?.original)
        }
      }}
      {...otherProps}
    />)
  }
}
