import React                from 'react'

import { Route, Router } from 'react-router'

import CookieBanner from 'react-cookie-banner'

import { NavBar } from './nav/NavBar'

import { Home } from './home'
import { LoginPanel       } from 'sdc-auth-react'
import { GoogleLoginPanel } from 'sdc-auth-google'

import { BuildingRoute } from './authoring/building/route'

import { ProjectsPanel  } from './authoring/projects/ProjectsPanel'
import   ProgressPanel    from './authoring/progress/LazyProgressPanel'
import   ValuesPanel      from './authoring/values/ValuesPanel'
import   DashBoard        from './authoring/dashboard/DashBoard'
import { SnowflakePanel } from './authoring/snowflake/panel'
import   StoryBoard       from './authoring/story'

import   BooksPanel    from './backoffice/books/BooksPanel'
import { UsersPanel  } from './backoffice/users/UsersPanel'
import { GenresPanel } from './backoffice/genres/panel'

import { history     } from './index'

import { uiBase      } from './env/constants'
import { loginURL    } from './user/login/urls'

import { projectsURL } from './authoring/projects/urls'
import { progressURL } from './authoring/progress/urls'
import { valuesURL   } from './authoring/values/urls'
import { dashURL     } from './authoring/dashboard/urls'
import { snowURL     } from './authoring/snowflake/urls'
import { boardURL    } from './authoring/story/urls'

import { booksURL    } from './backoffice/books/urls'
import { usersURL    } from './backoffice/users/urls'
import { genresURL   } from './backoffice/genres/urls'

import { Authenticated, Administrator } from 'sdc-auth-react'

import { languagesStore } from 'sdc-i18n-stores'

import './App.css'


export const App = () =>
<Router history={history}>
  <div lang={languagesStore.selectedLanguage}>
    <CookieBanner dismissOnScroll={false}
        message="This website is using cookies to track your user ID and to generally improve your experience. According to current EU laws, as of May 25th 2018, you must actively constent to cookie usage before being able to use this site." />
    <NavBar />

    <Route path={uiBase} exact component={Home} />
    <Route path={loginURL}     component={() => <LoginPanel>
        <GoogleLoginPanel clientID="307207593978-m35dd87p018rcs3tdkf7ku9hobaonb9t.apps.googleusercontent.com" />
    </LoginPanel>} />

    <BuildingRoute />
    <Route path={projectsURL}  component={Authenticated(ProjectsPanel)} />
    <Route path={dashURL}      component={Authenticated(DashBoard)} />
    <Route path={snowURL}      component={Authenticated(SnowflakePanel)} />
    <Route path={boardURL}     component={Authenticated(StoryBoard)} />
    <Route path={progressURL}  component={Authenticated(ProgressPanel)} />
    <Route path={valuesURL}    component={Authenticated(ValuesPanel)} />

    <Route path={booksURL}     component={Administrator(BooksPanel)} />
    <Route path={usersURL}     component={Administrator(UsersPanel)} />
    <Route path={genresURL}    component={Administrator(GenresPanel)} />

  </div>
</Router>
