import React from 'react'
import cn    from 'classnames'

import NumberFormat from 'react-number-format'

import { labelsStore } from 'sdc-i18n-stores'

export const WordCount = ({showZero,embedded,suffix,label = 'target.words',className,...props}) => (props.value || showZero) ?
  <NumberFormat {...props} className={cn(!embedded && 'word-count',className)}
    displayType='text' suffix={(suffix === undefined) ? ' '+labelsStore.labelFor(label)+' ' : suffix}
    thousandSeparator=' ' decimalScale={0} /> : null
