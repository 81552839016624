import React from 'react'
import { inject, observer } from 'mobx-react'

import * as RS from 'reactstrap'

import { EntryFields } from './EntryFields'

export const EntryFieldsTab = inject('controlsStore')(observer(({controlsStore,type,tabId,fields}) => {
  if (!fields) {
    const key = 'tab.'+type+'.'+tabId
    fields = ((controlsStore.valuesByKey[key] || {}).value || '').split(',')
  }
  return <RS.TabPane tabId={tabId} >
    <EntryFields fields={fields} />
  </RS.TabPane>
}))
