import { action, autorun, observable } from 'mobx'
import _      from 'lodash'
import moment from 'moment'

import { requiredParam } from 'sdc-utilities'

class TimelineStore {

  @observable povs    = []
  @observable povIDs  = {}
  @observable locations   = []
  @observable locationIDs = {}
  @observable slots   = []
  @observable minTime = moment().add(-1, 'day').valueOf()
  @observable maxTime = moment().add( 1, 'day').valueOf()
  @observable showParty    = false
  @observable showLocation = true

  constructor(options) {
    const {
      scenesStore = requiredParam('scenesStore'),
    } = options
    this.scenesStore = scenesStore
    autorun(() => {
      if (Object.keys(scenesStore.dataList).length) {
        if (scenesStore.sequence) {
          this.parseSequence()
        } else {
          this.obtainSequences(scenesStore.dataList || [])
        }
        this.obtainTimescale()
      }
    })
  }

  @action
  toggleShowParty = () => {
    this.showParty = !this.showParty
    this.parseSequence()
  }

  @action
  toggleShowLocation = () => {
    this.showLocation = !this.showLocation
    this.parseSequence()
  }

  @action
  parseSequence = () => {
    const scenes = this.scenesStore.dataList[this.scenesStore.sequence] || []
    this.obtainLocations(scenes)
    this.obtainPOVs(scenes)
    this.obtainSlots(scenes)
  }

  @action
  obtainSequences = sequences => {
    const toMinTime = (min,scene) => {
      const time = startTime(scene)
      return (min < time) ? min : time
    }
    const toMaxTime = (max,scene) => {
      const time = endTime(scene)
      return (max > time) ? max : time
    }
    const minTime = scenes => scenes.reduce(toMinTime, startTime(scenes[0]))
    const maxTime = scenes => scenes.reduce(toMaxTime,   endTime(scenes[0]))
    const toSlot = sequence => {
      const scenes = sequences[sequence].filter(included).filter(hasTime)
      return scenes.length ? {
        id         : sequence,
        group      : 0,
        title      : 'Sequenz '+sequence,
        start_time : minTime(scenes),
        end_time   : maxTime(scenes),
        className  : 'sequence',
      } : {}
    }
    this.povs = [{
      id    : 0,
      title : 'Sequenzen',
    }]
    this.slots = Object.keys(sequences).map(toSlot).filter(slot => slot.id)
  }

  @action
  obtainLocations = scenes => {
    const toGroup = (pov,index) => ({
      id    : index+1,
      title : pov,
    })
    const isNamed = name => name
    const includedScenes = scenes.filter(included)
    const locations = includedScenes.map(toLoc)
    this.locations   = _.uniq(locations.filter(isNamed)).map(toGroup)
    this.locationIDs = this.locations.reduce(itemToID, {})
  }

  @action
  obtainPOVs = scenes => {
    const locGroup = {
      id    : 0,
      title : 'Locations',
    }
    const toGroup = (pov,index) => ({
      id    : index+1,
      title : pov,
    })
    const isNamed = name => name
    const includedScenes = scenes.filter(included)
    const povs = this.showParty ?
      [...includedScenes.map(toPOV),...includedScenes.map(toParty).flatten()] :
      includedScenes.map(toPOV)
    this.povs   = this.showLocation ? [locGroup,...(_.uniq(povs.filter(isNamed)).map(toGroup))] : _.uniq(povs.filter(isNamed)).map(toGroup)
    this.povIDs = this.povs.reduce(itemToID, {})
  }

  @action
  obtainSlots = scenes => {
    const locToSlot = ({scene,name}) => ({
      id         : scene.id+':loc',
      group      : 0,
      title      : name || '?',
      start_time : startTime(scene),
      end_time   : endTime(scene),
      className  : 'location',
    })
    const povToSlot = ({scene,name,party}) => ({
      id         : scene.id+':'+name,
      group      : this.povIDs[name],
      title      : scene.title || 'unbenannt',
      start_time : startTime(scene),
      end_time   : endTime(scene),
      className  : party ? 'party' : 'pov',
    })
    const toSlots = scene => {
      const loc   = [toLoc(scene)].map(p => ({scene, name : p})).map(locToSlot)
      const pov   = [toPOV(scene)].map(p => ({scene, name : p})).map(povToSlot)
      const party = toParty(scene).map(p => ({scene, name : p, party: true})).map(povToSlot)
      return this.showParty ? [...loc,...pov,...party] : [...loc,...pov]
    }

    this.slots = scenes.filter(included).filter(hasTime).map(toSlots).flatten()
  }

  @action
  obtainTimescale = () => {
    const toMinTime = (min,slot) => {
      const time = slot.start_time
      return (min < time) ? min : time
    }
    const toMaxTime = (max,slot) => {
      const time = slot.end_time
      return (max > time) ? max : time
    }

    if (this.slots.length) {
      this.minTime = this.slots.reduce(toMinTime, this.slots[0].start_time)
      this.maxTime = this.slots.reduce(toMaxTime, this.slots[0].end_time)
      const offset = (this.maxTime - this.minTime) / 50
      this.minTime = this.minTime - offset
      this.maxTime = this.maxTime + offset
      setTimeout(action(() => {
        this.minTime = this.minTime - 1
        this.maxTime = this.maxTime + 1
      }))
    } else {
      this.minTime = moment().add(-1,'day').valueOf()
      this.maxTime = moment().add( 1,'day').valueOf()
    }
  }

}

const included = scene => scene.included
const hasTime  = scene => scene.content.time && (scene.content.duration || scene.content.timeEnd)
const toLoc    = scene => scene.content.loc || '?'
const toPOV    = scene => scene.content.pov || 'NN'
const toParty  = scene => (scene.content.party || '').split('\n')

const itemToID = (ids,item) => {
  ids[item.title] = item.id
  return ids
}

const startTime = scene => moment(scene.content.time+' Z').valueOf()
const   endTime = scene => scene.content.duration ?
    moment(scene.content.time+'Z').add(duration(scene)).valueOf() :
    moment(scene.content.timeEnd+' Z').valueOf()
const duration  = scene => moment.duration(scene.content.duration)

export const makeTimelineStore = options => new TimelineStore(options)
