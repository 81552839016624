import { action, autorun, observable, toJS } from 'mobx'

import _  from 'lodash'
import qs from 'qs'

import * as api from './api'

import projectsStore from '../../data/projects/store'

import {ReloadingStore} from 'sdc-mobx-stores'

import { history } from '../..'

export class ScenesStore extends ReloadingStore {

  @observable sequence = null
  @observable sections = []
  @observable thread   = null

  @observable values   = []

  @observable lastModified = null

  currentProject = null

  constructor(api) {
    super({
      listName : "list",
      entryName: "project",
      api,
    })
    autorun(() => {
      if (projectsStore.selected.id) {
        if (this.currentProject !== projectsStore.selected.id) {
          this.currentProject = projectsStore.selected.id
          this.setupReload({
            action  : this.reload,
            onClear : this.clearEntries,
          })
        }
      } else {
        this.clearReload()
      }
    })
  }

  reload = () => {
    this.list({
      params   : projectsStore.selected.id,
      callback : this.parseData,
    })()
  }

  parseData = deferred => action(payload => {
    this.setEntries(deferred)(payload)
    if (this.sequence) {
      this.parseSections()
    }
    this.parseValues()
    this.lastModified = new Date().valueOf()
  })

  @action
  clearEntries = () => {
    this.clearSelected()
    this.dataList = {}
  }

  selectSequence = sequence => action(() => {
    const params = qs.parse(history.location.search.slice(1))
    console.log(params)

    if (sequence) {
      if (sequence !== this.sequence) {
        const paramsSequence = parseInt(params.sequence || 0, 10)
        if (paramsSequence !== sequence) {
          params.sequence = sequence
          history.push(history.location.pathname +'?'+ qs.stringify(params))
        }
        this.sequence = sequence
        this.parseSections()
      }
    } else {
      if (sequence !== this.sequence) {
        if (params.sequence) {
          delete params.sequence
          history.push(history.location.pathname +'?'+ qs.stringify(params))
        }
      }
      this.sequence = null
      this.sections = []
    }
  })

  selectThread = thread => action(() => {
    this.thread = thread
  })

  parseSections = () => {
    this.sections = _.values(_.groupBy(this.dataList[this.sequence],'content.subseq'))
  }

  parseValues = () => {
    const scenes = _.flatten(Object.values(this.dataList).map(l => toJS(l)))
    this.values = scenes.filter(scene => scene.included).map((scene,index) => ({
      x : index,
      ...scene.content.values,
    }))
  }

  sum = field => this.sumRange(field,0,8)
  partial = (field,part) => this.sumRange(field, 2*part-1, 2*part)

  sumRange = (field,lower,upper) => {
    if (this.dataList.length === 0) return 0
    let acc = 0
    for (var i = lower; i <= upper; i++) {
      const data = this.dataList[i]
      if (data) {
        acc += _.sumBy(data, field)
      }
    }
    return acc
  }

  todoIconFor = scene => {
    const todo = scene.content.todo || ''
    if (todo.includes('!')) {
      return 'exclamation-circle'
    } else if (todo.includes('?')) {
      return 'question-circle'
    } else {
      return 'check-circle'
    }
  }

}

export default new ScenesStore(api)
