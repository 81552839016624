import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { action, observable } from 'mobx'

import NVD3Chart from 'react-nvd3'
import 'nvd3/build/nv.d3.css'

import cn from 'classnames'

import './ChartBox.scss'




@inject('progressState')
@observer
export class ChartBox extends Component {

  @observable stat = {
    max: false,
  }

  componentDidUpdate() {
    if (this.props.InnerContent) this.props.InnerContent.componentDidUpdate()
  }
  selectLayout = action((max) => {
    this.stat = {
      ...this.stat,
      max,
    }
    this.props.progressState.selectLayout(max ? this.props.max : 'all')
  })
  @action
  toggleMaximize = () => {
    this.selectLayout(!this.stat.max)
  }
  render() {
    const {title,max,select,className='',...passThrough} = this.props
    const iconClasses = cn(
      'fa',
      (this.stat || {}).max  && 'fa-compress',
      !(this.stat || {}).max && 'fa-expand',
    )
    return <div className={'gmcd-chart-box '+className}>
      <div className="box-header">
        <h3>
          <button className="pull-right btn" onClick={this.toggleMaximize}><i className={iconClasses}></i></button>
          {title}
        </h3>
      </div>
      <div className="box-content">
        <NVD3Chart {...passThrough} />
      </div>
    </div>
  }
}
