import _ from 'lodash'

import { toSeries, toPoint } from './mappers'

import * as du from '../../utils/DataUtils'

import { valuesStore } from './store'

const mapper = (field,first) => toSeries(toPoint({
  field,
  first,
}))

export const mapField = (values,field,first) => mapper(field,first)(values || []).filter(v => v.y)

export const mappedValues = values => _.flatten(Object.keys(valuesStore.values).map(key => ([
  {values: mapField(values, key, true),  key:                            key, color: valuesStore.values[key]?.color1},
  {values: mapField(values, key, false), key: valuesStore.values[key]?.title, color: valuesStore.values[key]?.color2},
])))
