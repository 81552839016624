import historicStore from './store'
import * as du from '../../utils/DataUtils'

import { cutoffSince, toSeries, toPoint } from './mappers'

export const getHistoric = () => historicStore.dataList.toJS()

const cutoffLong  = cutoffSince(10,'years')
const cutoffShort = cutoffSince(12,'months')

const getHistoricalSeries  = field => toSeries(field,  cutoffLong, toPoint(field))(getHistoric())
const getHistoricalWords   = ()    => toSeries('words',cutoffShort,toPoint('words'))(getHistoric())
const getHistoricalAdded   = ()    => toSeries('added',cutoffShort,toPoint('added'))(getHistoric())
const getHistoricalDeleted = ()    => toSeries('deleted',cutoffShort,toPoint('deleted',-1))(getHistoric())
const getHistoricalChanges = ()    => toSeries('updated',cutoffShort,toPoint('updated'))(getHistoric())

export const getHistoricalData = () => ([
  {values: getHistoricalSeries('total'),  key: 'Total',   color: '#999'},
  {values: getHistoricalSeries('trash'),  key: 'Trash',   color: '#f99'},
  {values: getHistoricalSeries('actual'), key: 'Written', color: '#090'},
  {values: getHistoricalSeries('togo'),   key: 'To go',   color: '#900'},
  {values: getHistoricalSeries('target'), key: 'Target',  color: '#99f'},
])

export const getHistoricWordsData = () => ([
  {values: getHistoricalWords(),   key: latest().words   +' words',   color: '#999'},
  {values: getHistoricalAdded(),   key: latest().added   +' added',   color: '#46e'},
  {values: getHistoricalChanges(), key: latest().updated +' changed', color: '#6c6'},
  {values: getHistoricalDeleted(), key: latest().deleted +' deleted', color: '#c66'},
])

export const latest  = () => du.lastEntryOf(getHistoric())
export const current = () => latest().actual
export const target  = () => latest().target
export const togo    = () => target() - current()
export const wpd     = (days = 7) => du.averageOf(getHistoricalAdded(), days)
export const sum     = (days = 7) => du.sumOf(getHistoricalAdded(), days)
