import React  from 'react'

import './summary.scss'

export default class Summary extends React.Component {
  constructor(props) {
    super(props)
    this.isValidated = this.isValidated.bind(this)
  }
  isValidated() {
    console.log('validating...')
    this.props.submitProject()
    return false
  }
  render() {
    return <div className="step">
      <h1>We are all set!</h1>
      <table className="gmcd-report">
        <tbody>
          <tr>
            <td>Working Title</td>
            <th>{this.props.project.title}</th>
          </tr>
          <tr>
            <td>Shared Folder</td>
            <th>/Manuscripts/{this.props.project.folder}</th>
          </tr>
          <tr>
            <td>Words per Page</td>
            <th>{this.props.project.wordsPerPage}</th>
          </tr>
          <tr>
            <td>Words per Day</td>
            <th>{this.props.project.wordsPerDay}</th>
          </tr>
        </tbody>
      </table>
    </div>
  }
}
