import React from 'react'
import cn    from 'classnames'

import { inject, observer } from 'mobx-react'
import { Label  } from 'sdc-i18n-react'

import { BookAd     } from 'sdc-amazon-ads'

import { ContentPanel } from '../../components'

import { WordCount  } from '../../components/manuscript/WordCount'
import { PageCount  } from '../../components/manuscript/PageCount'
import { WeekCount  } from '../../components/manuscript/WeekCount'
import { Percentage } from '../../components/numbers/Percentage'
import { ETA        } from '../progress/ETA'
import ProgressIndicator from './ProgressIndicator'

import * as historicalData from '../../data/historic/selectors'

import './style.scss'


const DashBoard = ({labelsStore,currentStore,projectsStore,scenesStore,nodes}) => {
  //console.log(nodes)
  //if (nodes) {
    // const items = nodes.map(node =>
    //   <li key={node}>{node}</li>
    // )
    const actualTarget = scenesStore.sum('actualTarget')
    const wordCount    = scenesStore.sum('wordCount')
    const togo         = actualTarget - wordCount
    const scale        = actualTarget / currentStore.targets[currentStore.genre].total

    const partialTarget = 200 * Math.floor(actualTarget / 3000)

    const kuRate = labelsStore.labelFor('ku.rate') === 'ku.rate' ? 0.0025 : parseFloat(labelsStore.labelFor('ku.rate'))

    return (
      <ContentPanel className="dashboard">
        <BookAd slot="progress" />

        <h2><Label value="dashboard.title" /></h2>

        <div className="col-md-11"><table className="table table-blue table-bordered table-condensed">
        <colgroup>
          <col width="30%" />
          <col width="5%" />
          <col width="5%" />
          <col width="5%" />
          <col width="5%" />
          <col width="5%" />
          <col width="5%" />
          <col width="5%" />
          <col width="5%" />
          <col width="5%" />
        </colgroup>
        <tbody>
          <tr className="text-center">
            <th className="text-left">Progress</th>
            <th className={cn(currentStore.genre === 'romance' && 'genre-selected')} onClick={currentStore.selectGenre('romance')}><Label value="dashboard.romance"   /></th>
            <th className={cn(currentStore.genre === 'science' && 'genre-selected')} onClick={currentStore.selectGenre('science')}><Label value="dashboard.science"   /></th>
            <th className={cn(currentStore.genre === 'fantasy' && 'genre-selected')} onClick={currentStore.selectGenre('fantasy')}><Label value="dashboard.fantasy"   /></th>
            <th><Label value="dashboard.scaled"    /></th>
            <th><Label value="dashboard.offset"    /></th>
            <th className="genre-planned"><Label value="dashboard.actual"    /></th>
            <th><Label value="dashboard.wordcount" /></th>
            <th><Label value="dashboard.togo"      /></th>
            <th><Label value="dashboard.completed" /></th>
          </tr>
          {[1,2,3,4].map(part => {
            const pScaledTarget = (currentStore.targets[currentStore.genre].parts[part] * scale).toFixed()
            const pGivenTarget = scenesStore.partial('givenTarget',part)
            const pActualTarget = scenesStore.partial('actualTarget',part)
            const pWordCount = scenesStore.partial('wordCount',part)
            return <tr key={part}>
            <td>
              <ProgressIndicator count={pWordCount} target={pActualTarget} scale={partialTarget} />
            </td>
            <td className={cn(currentStore.genre === 'romance' && 'genre-selected')}><WordCount value={currentStore.targets.romance.parts[part]} /></td>
            <td className={cn(currentStore.genre === 'science' && 'genre-selected')}><WordCount value={currentStore.targets.science.parts[part]} /></td>
            <td className={cn(currentStore.genre === 'fantasy' && 'genre-selected')}><WordCount value={currentStore.targets.fantasy.parts[part]} /></td>
            <td><WordCount value={pScaledTarget} /></td>
            <td><WordCount value={pActualTarget - pScaledTarget} /></td>
            <td className="genre-planned"><WordCount value={pActualTarget} /></td>
            <td style={{backgroundColor: (pGivenTarget - pWordCount === 0 ? '#cfc' : undefined)}}><WordCount value={pWordCount} /></td>
            <td style={{backgroundColor: (pGivenTarget - pWordCount === 0 ? '#cfc' : undefined)}}><WordCount value={pGivenTarget - pWordCount} /></td>
            <td style={{backgroundColor: (pGivenTarget - pWordCount === 0 ? '#cfc' : undefined)}}><Percentage value={pWordCount / pActualTarget} /></td>
          </tr>})}
          <tr>
            <th></th>
            <th className={cn(currentStore.genre === 'romance' && 'genre-selected')}><WordCount value={currentStore.targets.romance.total} /></th>
            <th className={cn(currentStore.genre === 'science' && 'genre-selected')}><WordCount value={currentStore.targets.science.total} /></th>
            <th className={cn(currentStore.genre === 'fantasy' && 'genre-selected')}><WordCount value={currentStore.targets.fantasy.total} /></th>
            <th><Percentage value={scale} /></th>
            <th></th>
            <th className="genre-planned"><WordCount value={actualTarget} /></th>
            <th><WordCount value={wordCount} /></th>
            <th><WordCount value={togo} /></th>
            <th><Percentage value={wordCount / actualTarget} /></th>
          </tr>
          <tr>
            <th className="text-right"><Label value="dashboard.pages" pages={projectsStore.selected.wordsPerPage} /></th>
            <th className={cn(currentStore.genre === 'romance' && 'genre-selected')}><PageCount value={currentStore.targets.romance.total / projectsStore.selected.wordsPerPage} /></th>
            <th className={cn(currentStore.genre === 'science' && 'genre-selected')}><PageCount value={currentStore.targets.science.total / projectsStore.selected.wordsPerPage} /></th>
            <th className={cn(currentStore.genre === 'fantasy' && 'genre-selected')}><PageCount value={currentStore.targets.fantasy.total / projectsStore.selected.wordsPerPage} /></th>
            <th></th>
            <th></th>
            <th className="genre-planned text-right">
              <PageCount value={historicalData.target() / projectsStore.selected.wordsPerPage} />
              <span>KU: {(historicalData.target() / projectsStore.selected.wordsPerPage * kuRate).toLocaleString('de', { style: 'currency', currency: 'EUR'})}</span>
            </th>
            <th><PageCount value={wordCount / projectsStore.selected.wordsPerPage} /></th>
            <th className="highlighted">
              {false && <span ng-show="chart.dataBook">appCtrl.togo() / avgWordCount() | number : 0 /</span>}
                      <WeekCount value={(togo / historicalData.wpd() / 5).toFixed()} /></th>
            <th className="text-center highlighted"><ETA wordsPerDay={historicalData.wpd()} /></th>
          </tr>
          </tbody>
        </table></div>

      </ContentPanel>
    )
        // <ul>{items}</ul>
  // } else {
  //   return (
  //     <div>no nodes</div>
  //   )
  // }
}

export default inject('labelsStore','currentStore','projectsStore','scenesStore')(observer(DashBoard))
