import moment from 'moment'
import _ from 'lodash'

export const lastValueOf = (data, field='y') => (data && data.length) ? data.slice(-1)[0][field] : ""
export const lastEntryOf = (data) => (data && data.length) ? data.slice(-1)[0] : {}

export const averageOf = (data, days) => {
  if (data && data.length) {
    const cutoff = moment().add(-days,'days').toDate()
    const raw = data.filter(d => d.x > cutoff)
    return Math.round(1.0 * _.sumBy(raw, 'y') / days)
  } else {
    return 0
  }
}
export const sumOf = (data, days) => {
  if (data && data.length) {
    const cutoff = moment().add(-days,'days').toDate()
    const raw = data.filter(d => d.x > cutoff)
    return Math.round(_.sumBy(raw, 'y'))
  } else {
    return 0
  }
}
