import React from 'react'
import DebouncedInput from 'react-debounce-input'

export default ({project,updateProject}) => (
  <div className="step">
    <h1>Manuscript Settings</h1>
    <p>Now, you may adjust some project settings.<br />
        You may safely leave the defaults for now, as you can change these settings at any later time.</p>
    <form style={{width: '20vw', minWidth: '150px'}}>
      <label htmlFor="ms-title">
          Words per Page <span style={{fontWeight: 'normal'}}>to use for estimates</span></label>
      <div className="input-group" style={{marginBottom: '12px'}}>
        <span className="input-group-addon"><i className="fa fa-file-text-o" /></span>
        <DebouncedInput minLength={3} debounceTimeout={300}
            className="form-control" type="text" placeholder="Enter words per page..."
            value={project.wordsPerPage}
            onChange={(e) => updateProject({wordsPerPage: parseInt(e.target.value,10)})} />
      </div>
      <label htmlFor="ms-folder">
          Words per Day <span style={{fontWeight: 'normal'}}>to use as your daily target</span></label>
      <div className="input-group" style={{marginBottom: '12px'}}>
        <span className="input-group-addon"><i className="fa fa-calendar" /></span>
        <DebouncedInput minLength={3} debounceTimeout={300}
            className="form-control" type="text" placeholder="Enter your daily target..."
            value={project.wordsPerDay}
            onChange={(e) => updateProject({wordsPerDay: parseInt(e.target.value || 250)})} />
      </div>
    </form>
  </div>
)
