import { action, autorun, observable } from 'mobx'

import * as store   from 'sdc-mobx-stores'
import { requiredParam } from 'sdc-utilities'

import { UpdatingStore, editingMode, editingType }  from 'sdc-cms-writing'


export default class FilteringStore extends UpdatingStore {

  @observable state = {}

  constructor({
    worldsStore = requiredParam('worldsStore'),
    ...options
  }) {
    super({...options})

    this.worldsStore = worldsStore

    autorun(() => {
      if (this.state.field && this.state.linkTo) {
        this.clearSelected()
        this.reload()
      } else {
        this.clearEntries()
      }
    })
    autorun(() => {
      if (editingType.typeID === this.typeID) {
        this.typesStore.selectType(this.typeID)
        this.editingStore.updateListener = this
        this.editingStore.selectField({})()
        if (editingMode.isViewMode.get()) {
          this.clearSelected()
        }
      }
    })
  }

  @action
  setState = state => {
    if (this.state.field !== state.field || this.state.linkTo !== state.linkTo) {
      this.state = state
    }
  }
  setFilter = (world,series,story,family) => {
      if (family) {
        this.setState({
          field: 'family',
          linkTo: family,
          scope: 'world',
          parent: world,
        })
      } else if (story) {
        this.setState({
          field: 'story',
          linkTo: story,
          scope: 'world',
          parent: world,
        })
      } else if (series) {
        this.setState({
          field: 'series',
          linkTo: series,
          scope: 'world',
          parent: world,
        })
      } else {
        this.setState({
          field: 'world',
          linkTo: world,
          scope: 'world',
          parent: world,
        })
      }
  }

  reload = () => {
    this.list({params:this.state})()
  }

  afterSelect = () => {
    editingType.clear()
    this.typesStore.selectType(this.typeID)
    this.editingStore.updateListener = this
    this.editingStore.setSelected(this.selected)
    this.editingStore.selectField({})()
    setTimeout(() => {
      editingType.setTypeID(this.typeID)
    },1)
  }

  afterCreate = (entry) => {
    entry.values = {}
    this.setSelected(entry)
    this.typesStore.selectType(this.typeID)
    const field = this.typesStore.fields.world
    this.editingStore.setBuffers(entry)
    this.editingStore.assign(field)({
      value: this.worldsStore.selected.id
    })
  }

  updateSelected = (entry) => {
    this.selected = entry
    this.dataList = store.update(this.dataList)(this.selected)
  }

}
