import { autorun } from 'mobx'

import * as api from './api'

import { userStore      } from 'sdc-auth-user'
import { ReloadingStore } from 'sdc-mobx-stores'


export class UsersStore extends ReloadingStore {

  constructor(api) {
    super({
      listName : "users",
      entryName: "user",
      api,
    })
    autorun(() => {
      if (userStore.user.admin) {
        this.setupReload({
          action : this.reload,
          onClear: this.clearEntries,
          interval : 600,
        })
      } else {
        this.clearReload()
      }
    })
  }

  reload = () => {
    this.list({})()
  }

}

export default new UsersStore(api)
