import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React    from 'react'
import ReactDOM from 'react-dom'
import qs       from 'qs'

import { AppContainer } from 'react-hot-loader'

import { autorun, configure } from 'mobx'
import { Provider } from 'mobx-react'
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import { Router } from 'react-router'
import createHistory from 'history/createBrowserHistory'

import Modal from 'react-modal'

import './design/custom.scss'
import 'font-awesome/css/font-awesome.css'
import './index.css'

import { userStore } from 'sdc-auth-user'

import { App } from './App'

import { uiBase }   from './env/constants'

import * as otherStores from './index-stores'

import { unregister } from './registerServiceWorker'


configure({
  enforceActions : 'always',
})

Modal.setAppElement('#root')

const baseHistory = createHistory()
const routingStore = new RouterStore()

export const history = syncHistoryWithStore(baseHistory, routingStore)
history.subscribe(otherStores.editingStore.onHistory)

const stores = {
  routing: routingStore,
  ...otherStores
}

let authenticated = false

autorun(() => {
  if (userStore.state.authenticated && !authenticated) {
    console.log(`going back in history, user = ${userStore.user.name}`)
    history.goBack()
  }
  authenticated = userStore.state.authenticated
})
history.listen((location,action) => {
  console.log(action, location.pathname, location.state)
  if (location.search) {
    const params = qs.parse(location.search.slice(1))
    console.log(params)
    if (params.project) {
      stores.projectsStore.selectByID(params.project)
    }
    if (params.sequence) {
      const sequence = parseInt(params.sequence, 10)
      if (stores.scenesStore.sequence !== sequence) {
        stores.scenesStore.selectSequence(sequence)()
      }
    }
  }
  //sendPageView(location.pathname)
})

const render = Component =>
ReactDOM.render(
  <AppContainer>
    <Provider {...stores} >
      <Router history={history} path={uiBase} >
    	  <Component />
      </Router>
    </Provider>
  </AppContainer>,
  document.getElementById('root')
)

render(App)

if (module.hot) {
  module.hot.accept('./App', () => render(App))
}

unregister()
