import  React from 'react'

import { inject, observer } from 'mobx-react'

import { DataTable } from '../../table'
import * as column   from '../../table/columns'

const columns = store => ([
  column.createDelete({
    type : 'genre',
    onCreate : store.create(),
    onDelete : store.remove,
    canDelete : cell => !(cell.original.values || {}).name
  }),
  column.name(),
])

export const FormatsList = inject('formatsStore')(observer(({formatsStore}) =>
<DataTable store={formatsStore} columns={columns(formatsStore)}
/>))
