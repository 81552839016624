import { action, autorun, observable } from 'mobx'
import _ from 'lodash'

import { ContentApi } from 'sdc-cms-client'
import { UpdatingStore, editingMode, editingType } from 'sdc-cms-writing'


const typeID = 'P2Mr8oTLC1Oh5kghKGgUQNh8mJGqD4Qv'


export class GenresStore extends UpdatingStore {

  @observable genres = {}

  constructor({
    indexStore,
    ...options
  }) {
    super({
      typeID,
      ...options
    })
    this.indexStore = indexStore

    this.reload()
    autorun(() => {
      if (editingType.typeID === this.typeID) {
        this.typesStore.selectType(this.typeID)
        this.editingStore.updateListener = this
        this.editingStore.selectField({})()
        if (editingMode.isViewMode.get()) {
          this.clearSelected()
        }
      }
    })
  }

  afterSelect = () => {
    editingType.clear()
    this.typesStore.selectType(this.typeID)
    this.indexStore.setParentID(this.selected.id)
    this.editingStore.updateListener = this
    this.editingStore.setSelected(this.selected)
    this.editingStore.selectField({})()
    setTimeout(() => {
      editingType.setTypeID(this.typeID)
    },1)
  }

  afterCreate = entry => {
    entry.values = {}
    this.setSelected(entry)
    this.typesStore.selectType(this.typeID)
    this.editingStore.setBuffers(entry)
  }

  reload = () => {
    this.list({callback:this.parseGenres})()
  }

  parseGenres = deferred => action(payload => {
    this.genres = _.keyBy(payload.content, 'id')
    this.setEntries(deferred)(payload)
  })

  name = id => id ? ((this.genres[id] || {}).values || {}).name : '--'

}

export const makeGenresStore = ({backend,...options}) => new GenresStore({...options,api:ContentApi({backend,typeID})})
