import React from 'react'
import { observer } from 'mobx-react'

import { BookAd  } from 'sdc-amazon-ads'
import { Label   } from 'sdc-i18n-react'
import { Gallery } from './feature-gallery'

import { LoginButton } from './login'

import './home.scss'

export const Home = observer(() =>
<div className="gmcd-home">
  <BookAd slot="home" style={{marginTop: '16px'}} />
  <Label as="h1" value="home.slogan" />
  <div style={{marginRight: '150px'}}><Gallery /></div>
  <div>
    <h4>
      <LoginButton />
    </h4>
  </div>
  <div className="media">
    <img className="mr-4 pull-left" src="/project-wizard.png" alt="Create Project Wizard" width="834"
         srcSet="/project-wizard-half.png 417w, /project-wizard.png 834w" />
    <div className="media-body">
      <h2><Label value="home.setup.intro" /></h2>
      <ul>
        <li><Label value="home.setup.header1" />
          <Label as="div" className="details" value="home.setup.note1" />
        </li>
        <li><Label value="home.setup.header2" />
          <Label as="div" className="details" value="home.setup.note2" />
        </li>
        <li><Label value="home.setup.header3" />
          <Label as="div" className="details" value="home.setup.note3" />
        </li>
      </ul>
    </div>
  </div>
</div>)
