import { action, observable } from 'mobx'

const layouts = {
  all : [
    { i:'graph',  x:0, y:0, w:1, h:1},
  ],
}

export class ValuesPanelState {

  @observable state = {}

  constructor() {
    this.selectLayout('all')
  }

  @action
  selectLayout = key => {
    const layout = layouts[key]
    this.state = {
      ...this.state,
      layout,
      key,
    }
    setTimeout(() => {
      this.setMargin()
    }, 10)
  }

  @action
  setMargin = () => {
    const margin = (this.state.key === 'all') ? [16,16] : [16,0]
    this.state = {
      ...this.state,
      margin,
    }
  }
}

export const valuesPanelState = new ValuesPanelState()
