import React from 'react'
import { observer  } from 'mobx-react'

import { NavLink   } from 'react-router-dom'

import { Label } from 'sdc-i18n-react'

export const DynamicNavLink = observer(({show,label,icon,to}) => show ?
<li className="nav-item">
  <NavLink className="nav-link" to={to}>
    <i className={'fa fa-'+icon}></i> <Label value={label} />
  </NavLink>
</li> : null)
