import { autorun, action, observable, toJS } from 'mobx'

import _ from 'lodash'

import * as api from './api'

import projectsStore from '../../data/projects/store'

import {ReloadingStore} from 'sdc-mobx-stores'


export class ValuesStore extends ReloadingStore {

  @observable values = {}

  currentProject = null

  constructor(api) {
    super({
      listName : "values",
      api,
    })
    autorun(() => {
      if (projectsStore.selected.id) {
        if (this.currentProject !== projectsStore.selected.id) {
          this.currentProject = projectsStore.selected.id
          this.clear()
          this.setupReload({
            action : this.reload,
            onClear: this.clearEntries,
          })
        }
      } else {
        this.clearReload()
      }
    })
  }

  @action
  clear = () => {
    this.values = {}
  }

  reload = () => {
    this.list({
      params   : projectsStore.selected.id,
      callback : this.parseData,
    })()
  }
  parseData = deferred => action(payload => {
    this.setEntries(deferred)(payload)
    const values = this.dataList.map(value => ({
      ...value,
      id     : parseInt(value.id),
      color1 : this.parseColor(value.color,357),
      color2 : this.parseColor(value.color,204),
    }))
    this.values = _.keyBy(values, 'id')
  })

  parseColor = (color,scale) => '#'+ color.split(' ').map(c => ('0'+Math.min(255,Math.floor(scale * parseFloat(c))).toString(16)).slice(-2)).join('')

}

export const valuesStore = new ValuesStore(api)
