import React from 'react'
import { inject, observer } from 'mobx-react'

import Modal from 'react-modal'
import Steps from 'react-stepzilla'

import { BookAd } from 'sdc-amazon-ads'

import Step1 from './Naming'
import Step2 from './Settings'
import Step3 from './Summary'
import Step4 from './SubmitProject'

import sanitized from '../../../utils/StringSanitizer'

import './CreateProjectDialog.scss'


const initialState = {
  title: '',
  folder: '',
  wordsPerDay: 500,
  wordsPerPage: 250,
}

@inject('projectsStore')
@observer
export default class CreateProjectDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }
  closeModal = () => this.props.projectsStore.hideCreateDialog()
  title = 'Set up a new Writing Project'

  updateProject = (update) => {
    if (update.title) {
      this.setState({
        ...this.state,
        ...update,
        folder: sanitized(update.title)
      })
    } else {
      this.setState({
        ...this.state,
        ...update
      })
    }
  }
  submitProject = () => this.props.projectsStore.create(this.state)().then(this.props.projectsStore.hideCreateDialog)

  steps = () => [
    { component: <Step1 project={this.state} updateProject={this.updateProject} />,
      name: 'Working Title'},
    { component: <Step2 project={this.state} updateProject={this.updateProject} />,
      name: 'Project Settings'},
    { component: <Step3 project={this.state} submitProject={this.submitProject} />,
      name: 'Summary'},
    { component: <Step4 project={this.state} />,
      name: 'Done'},
  ]
  render() {
    return <Modal isOpen={this.props.projectsStore.state.creating} contentLabel={this.title}
          onRequestClose={this.closeModal} shouldCloseOnOverlayClick={false}
          style={{overlay : { backgroundColor: 'rgba(102,102,102,0.33)', top: 50}, content: {border: 'solid 3px #666'}}}>
      <h1 style={{borderBottom: 'solid 3px #cef', paddingBottom: '6px', marginTop: 0}}>{this.title}</h1>
      <BookAd slot="modal" />
      <div className="step-progress">
        <Steps steps={this.steps()}
            nextTextOnFinalActionStep="Create Project..."
            prevBtnOnLastStep={false} />
      </div>
      <button className="btn btn-warning" onClick={this.closeModal}>
        Cancel
      </button>
    </Modal>
  }
}
