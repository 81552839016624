const illegalRe = /[/?<>\\:*|":\s-]/g
const controlRe = /[\x00-\x1f\x80-\x9f]/g
const reservedRe = /^\.+$/
const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i
const windowsTrailingRe = /[. ]+$/

const sanitize = (input, replacement) => {
  const sanitized = input.replace(
    illegalRe, replacement
  ).replace(controlRe, replacement
  ).replace(reservedRe, replacement
  ).replace(windowsReservedRe, replacement
  ).replace(windowsTrailingRe, replacement)
  return truncate(sanitized.replace(/_{2,}/g,'_'))
}

const truncate = (input) => input.substring(0,100)

const sanitized = (input, options) => {
  let replacement = (options && options.replacement) || '_'
  let output = sanitize(input, replacement)
  if (replacement === '')
    return output
  else
    return sanitize(output, '')
}

export default sanitized
