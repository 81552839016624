import { autorun, action, observable } from 'mobx'

import * as api from './api'

import projectsStore from '../projects/store'

import { ReloadingStore } from 'sdc-mobx-stores'
import { subscribe      } from 'sdc-publish-subscribe'

import { TOPIC_PROJECT_DATA_LOADED } from '../topics'

const factors = [0,25,30,30,20]


export class CurrentStore extends ReloadingStore {

  @observable entriesFine = []
  @observable hourly = []
  @observable hourlyFine = []

  @observable state = {}

  @observable date = null
  @observable genre = 'science'

  @observable targets = {
    fantasy : {
      parts : [],
      total : 120000,
    },
    science : {
      parts : [],
      total :  70000,
    },
    romance : {
      parts : [],
      total :  40000,
    },
  }

  currentProject = null

  constructor(api) {
    super({
      listName : "entries",
      entryName: "project",
      api,
    })

    this.calcTargets()

    subscribe(TOPIC_PROJECT_DATA_LOADED, this.reload)

    autorun(() => {
      if (projectsStore.selected.id) {
        if (this.currentProject !== projectsStore.selected.id) {
          this.currentProject = projectsStore.selected.id
          this.clear()
          this.reload(projectsStore.selected.id)
          this.setupReload({
            action    : this.reload,
            onClear   : this.clearEntries,
            autostart : false,
          })
        }
      } else {
        this.clearReload()
      }
    })
  }

  @action
  calcTargets = () => {
    this.targets.fantasy.parts = factors.map(p => 500 * Math.round(p * this.targets.fantasy.total / 105 / 500))
    this.targets.science.parts = factors.map(p => 500 * Math.round(p * this.targets.science.total / 105 / 500))
    this.targets.romance.parts = factors.map(p => 500 * Math.round(p * this.targets.romance.total / 105 / 500))
  }

  selectGenre = genre => action(() => {
    this.genre = genre
  })

  @action
  clear = () => {
    this.state = {}
  }

  resetDate = () => {
    this.selectDate(null)
  }

  @action
  selectDate = date => {
    this.date = date
    delete this.state.lastModified
    this.reload(42)
  }

  moveDate = offset => action(() => {
    const date = new Date()
    date.setDate((this.date || new Date()).getDate()+offset)
    this.selectDate(date)
  })

  reload = projectCount => {

    if (!projectCount) return;
    if (!projectsStore.selected.id) return;

    this.list({
      params : {
        id  : projectsStore.selected.id,
        day : this.date || new Date(),
      },
      callback:this.parseData
    })()
  }
  parseData = deferred => action(payload => {
    if (!this.state.lastModified || this.state.lastModified < payload.lastModified) {
      this.setEntries(deferred)(payload)
      this.entriesFine = payload.entriesFine || []
      this.hourly      = payload.hourly || []
      this.hourlyFine  = payload.hourlyFine || []

      this.state = {
        lastModified : payload.lastModified
      }
    } else {
      deferred.resolve(this.dataList)
    }
  })

}

export default new CurrentStore(api)
