import { action, observable } from 'mobx'

const layouts = {
  all : [
    { i:'book',  x:0, y:0, w:6, h:1},
    { i:'pomo',  x:6, y:0, w:2, h:1},
    { i:'seqs',  x:8, y:0, w:6, h:1},

    { i:'words', x:0, y:1, w:6, h:1},
    { i:'daily', x:6, y:1, w:4, h:1},
    { i:'hourly',x:10, y:1, w:4, h:1},
  ],
  book  : [{ i:'book',  x:0, y:0, w:14, h:2}],
  seqs  : [{ i:'seqs',  x:0, y:0, w:14, h:2}],
  words : [{ i:'words', x:0, y:0, w:14, h:2}],
  daily : [{ i:'daily', x:0, y:0, w:14, h:2}],
  hourly: [{ i:'hourly',x:0, y:0, w:14, h:2}],
}

export class ProgressPanelState {

  @observable state = {}

  constructor() {
    this.selectLayout('all')
  }

  selectLayout = action((key) => {
    const layout = layouts[key]
    this.state = {
      ...this.state,
      layout,
      key,
    }
    setTimeout(() => {
      this.setMargin()
    }, 10)
  })
  @action
  setMargin = () => {
    const margin = (this.state.key === 'all') ? [16,16] : [16,0]
    this.state = {
      ...this.state,
      margin,
    }
  }
}

export default new ProgressPanelState()
