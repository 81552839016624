import React from 'react'
import { inject, observer } from 'mobx-react'

import { BookCard } from 'sdc-amazon-ads'

const BooksList = ({booksStore}) => booksStore.dataList.map(book =>
  <div key={book.id} style={{border: 'solid 1px #ccc', margin: '2px'}}>
    <div style={{textAlign: 'right'}}>
      <span style={{color: '#900', cursor: 'pointer'}} onClick={booksStore.remove(book)}><i className="fa fa-trash-o"></i></span>
    </div>
    <BookCard asin={book.values.asin} />
  </div>
)

export default inject('booksStore')(observer(BooksList))
