import React from 'react'

import * as RS from 'reactstrap'

import { EntryTab } from './EntryTab'


export const EntryTabs = ({onSelect,activeTab,tabIDs}) => <RS.Nav tabs>
  {tabIDs.map(tabId =>
    <EntryTab key={tabId} onSelect={onSelect} activeTab={activeTab} tabId={tabId} />
  )}
</RS.Nav>
