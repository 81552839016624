import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'

import _ from 'lodash'

import { Button } from 'sdc-react-basics'
import { Label  } from 'sdc-i18n-react'

import { Link } from 'react-router-dom'

import { boardURL    } from '../story/urls'
import { dashURL     } from '../dashboard/urls'
import { progressURL } from '../progress/urls'
import { valuesURL   } from '../values/urls'

import { SettingsButton } from './settings/button'

import { DataTable } from '../../table'
import * as column   from '../../table/columns'

import projectsStore from '../../data/projects/store'

import './ProjectList.css'
import './status.scss'


const columns = () => [
  column.createDelete({
    type      : 'project',
    onCreate  : projectsStore.showCreateDialog,
    onDelete  : projectsStore.remove,
    canDelete : cell => !cell.original.locked,
  }),
{
  Header: <Label value="project.title" />,
  accessor: 'title',
  headerClassName: 'left',
  Cell : cell => (
    <Link to={progressURL}>{cell.value}</Link>
  )
},{
  Header: <Label value="project.links" />,
  accessor: 'id',
  className: 'centered',
  width: 150,
  sortable: false,
  resizable: false,
  Cell : cell => (
    <span className='actions'>
      <Link     to={`${dashURL}?project=${cell.original.id}`}><Button title="project.overview" icon="tasks"      /></Link>
      <Link    to={`${boardURL}?project=${cell.original.id}`}><Button title="project.board"    icon="th"         /></Link>
      <Link to={`${progressURL}?project=${cell.original.id}`}><Button title="project.progress" icon="line-chart" /></Link>
      <Link   to={`${valuesURL}?project=${cell.original.id}`}><Button title="project.values"   icon="money"      /></Link>
      <SettingsButton onClick={projectsStore.showUpdateDialog} />
    </span>
  )
},
  column.date({
    caption : 'project.created',
    field   : 'created',
  }),
  column.date({
    caption : 'project.updated',
    field   : 'updated',
  }),
  column.wc({
    caption : 'project.actual',
    field   : 'written',
    total   : _.sumBy(toJS(projectsStore.dataList),'written'),
  }),
  column.wc({
    caption : 'project.target',
    field   : 'target',
    total   : _.sumBy(toJS(projectsStore.dataList),'target'),
  }),
{
  Header: <Label value="project.progress" />,
  accessor: 'id',
  className: 'right',
  width: 150,
  sortable: false,
  resizable: false,
  Cell : cell =>
    <div key={cell.original.id} style={{position: 'relative', height: '100%'}}>
      <span style={{
        position : 'absolute',
        left : '0',
        width : (cell.original.target > 0 ? (100.0 * cell.original.written / cell.original.target).toFixed(1) : '0')+'%',
        height: '100%',
        backgroundColor: '#9c9',
      }} />
      <span style={{
        position : 'absolute',
        left : (cell.original.target > 0 ? (100.0 * cell.original.written / cell.original.target).toFixed(1) : '0')+'%',
        width : (cell.original.target > 0 ? (100.0 - 100.0 * cell.original.written / cell.original.target).toFixed(1) : '0')+'%',
        height: '100%',
        backgroundColor: '#c99',
      }} />
      <span style={{display : 'inline-block', position: 'absolute', right: '4px'}}>{cell.original.target > 0 ? (100.0 * cell.original.written / cell.original.target).toFixed(1) : '0'} %</span>
    </div>
},
  column.wcc({
    caption : 'project.togo',
    compute : entry => entry.target - entry.written,
    total   : _.sumBy(toJS(projectsStore.dataList), p => p.target - p.written),
  }),
{
  Header: <Label value="project.status" />,
  accessor: 'lockHolder',
  className: 'centered',
  width: 150,
  resizable: false,
  Cell : cell => (
    <span className="project-status">
      {!cell.original.filename && <i className="fa fa-question-circle missing" /> }
      {cell.original.locked && <i className="fa fa-lock locked" />}
      {cell.original.filename && !cell.original.locked && <i className="fa fa-unlock unlocked" /> }
      {' '} {cell.value}
    </span>
  ),
  Footer : <Label value="project.total" />
}]

export const ProjectList = inject('projectsStore')(observer(({projectsStore}) =>
<DataTable store={projectsStore}
  columns={columns()}
  defaultSorted={[{
    id : 'title',
    desc : false,
  }]}
/>))
