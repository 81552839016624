import React from 'react'
import { inject, observer, Provider } from 'mobx-react'

import * as RS from 'reactstrap'

import { editingType    } from 'sdc-cms-writing'

import { DetailsHeader  } from './DetailsHeader'

import { EntryFieldsTab } from '../entries/EntryFieldsTab'
import { EntryTabs      } from '../entries/EntryTabs'
import { EntryFields    } from '../entries/EntryFields'

import { detailsState   } from './DetailsState'

import ParentWorld   from '../entries/ParentWorld'
import ParentSeries  from '../entries/ParentSeries'
import ParentStories from '../entries/ParentStories'


@inject('uiStore','typesStore','editingStore','controlsStore')
@observer
export default class DetailsPanel extends React.Component {
  render() {
    const dynamic = () => {
      if (!this.props.type) return []

      const key = 'tabs.'+this.props.type
      const ids = (this.props.controlsStore.valuesByKey[key] || {}).value
      return ids ? ids.split(',') : []
    }
    const tabs = () => {
      if (this.props.type)
        return (this.props.tabIDs || []).concat(dynamic())
      else
        return (this.props.tabIDs || ["general"]).slice()
    }
    const tabIDs = () => {
      const ids = tabs()
      if (!(this.props.editingStore.selected.values|| {}).globally && !this.props.hideContext) {
        ids.push('context')
      } else {
        setTimeout(() => {
          if (detailsState.activeTab === 'context') {
            detailsState.selectTab('general')()
          }
        }, 100);
      }
      return ids
    }

    const allTabs = tabIDs() || []

    return this.props.controlsStore.valuesByKey && (!this.props.dataStore || editingType.typeID === this.props.dataStore.typeID) ?
    <div className={this.props.uiStore.showIndex ? 'col-md-5' : (!this.props.uiStore.maximized ? 'col-md-8' : 'col-md-4')}>
      <DetailsHeader caption={this.props.caption || (this.props.type + '.details')} />
      <Provider dataStore={this.props.dataStore}><form className="entry-details">
        <EntryTabs onSelect={detailsState.selectTab} activeTab={detailsState.activeTab} tabIDs={allTabs} />
        <RS.TabContent activeTab={detailsState.activeTab}>
          {this.props.children}
          {dynamic().map(tabId => <EntryFieldsTab key={tabId} type={this.props.type} tabId={tabId} />)}
          <RS.TabPane tabId="context" >
            <ParentWorld />
            <ParentSeries />
            <ParentStories />
            <EntryFields value={'tab.'+this.props.type+'.context'} />
          </RS.TabPane>
        </RS.TabContent>
      </form></Provider>
    </div> : null
  }

}
