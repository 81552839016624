import React from 'react'
import { inject, observer } from 'mobx-react'

import { ValueTextEdit } from 'sdc-cms-editing'

const ParentStories = inject('storiesStore')(observer(({storiesStore}) =>
  <ValueTextEdit field="story"  choices={storiesStore.dataList} />
))

export default ParentStories
