import React from 'react'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import { Label } from 'sdc-i18n-react'

@inject('worldsStore','typesStore')
@observer
export class TypePanel extends React.Component {

  @observable uiState = {
    expanded : this.props.expanded || this.props.alwaysOpen || false,
  }

  @action
  toggleExpanded = () => {
    if (!this.props.alwaysOpen) this.uiState = {
      ...this.uiState,
      expanded : !this.uiState.expanded,
    }
  }

  render() {
    return (this.props.visible || this.props.worldsStore.selected.id) ?
    <div className={'type-panel'+ (this.props.alwaysOpen ? '' : ' expandable')}>
      <h4 onClick={this.toggleExpanded} className={this.uiState.expanded ? 'expanded' : 'collapsed'} >
        {!this.props.alwaysOpen && this.uiState.expanded &&  <i className="float-right fa fa-caret-up" />}
        {!this.props.alwaysOpen && !this.uiState.expanded && <i className="float-right fa fa-caret-down" />}
        {this.props.typesStore.iconFor(this.props.type) && <i className={'fa fa-fw fa-'+this.props.typesStore.iconFor(this.props.type)} />}
        <Label value={this.props.type+'.heading'} />
      </h4>
      {(this.props.alwaysOpen || this.uiState.expanded) && this.props.children}
    </div> : null
  }
}
