import React from 'react'
import { inject, observer } from 'mobx-react'

import { DataTable } from '../../table'

const columns = [{
//   Header: <CreateProjectButton />,
//   accessor: 'id',
//   headerClassName: 'centered',
//   className: 'centered',
//   width: 30,
//   sortable: false,
//   resizable: false,
//   Cell : cell => (
//     <span>
//       {!cell.original.locked && <span style={{color: '#900', cursor: 'pointer'}}><i className="fa fa-trash-o"></i></span>}
//     </span>
//   )
// },{
  Header: 'Name',
  accessor: 'name',
  headerClassName: 'left',
//   Cell : cell => (
//     <Link to={progressURL}>{cell.value}</Link>
//   )
//},{
//   Header: 'Links',
//   accessor: 'id',
//   className: 'centered',
//   width: 120,
//   sortable: false,
//   resizable: false,
//   Cell : cell => (
//     <span className='actions'>
//       <Link to={dashURL}><i className="fa fa-tasks"></i></Link>
//       <Link to={boardURL}><i className="fa fa-th"></i></Link>
//       <Link to={progressURL}><i className="fa fa-line-chart"></i></Link>
//       <Link to={progressURL}><i className="fa fa-cogs"></i></Link>
//     </span>
//   )
// },{
//   Header: 'Created',
//   accessor: 'created',
//   className: 'centered',
//   width: 120,
//   resizable: false,
//   Cell : dateCell,
// },{
//   Header: 'Updated',
//   accessor: 'updated',
//   className: 'centered',
//   width: 120,
//   resizable: false,
//   Cell : dateCell,
// },{
//   Header: 'Status',
//   accessor: 'lockHolder',
//   className: 'centered',
//   width: 150,
//   resizable: false,
//   Cell : cell => (
//     <span>
//       {!cell.original.filename && <i className="fa fa-question-circle" style={{color: '#933'}}/> }
//       {cell.original.locked && <i className="fa fa-lock" style={{color: '#963'}}/>}
//       {cell.original.filename && !cell.original.locked && <i className="fa fa-unlock" style={{color: '#393'}}/> }
//       {' '} {cell.value}
//     </span>
//   )
}]

const UsersList = ({usersStore}) => {
  return (
    <DataTable store={usersStore} columns={columns}
      className='-highlight'
      style={{maxHeight:'400px'}}
      defaultSorted={[{
        id : 'name',
      }]}
    />
  )
}

export default inject('usersStore')(observer(UsersList))
