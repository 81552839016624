import React from 'react'

import { Container, Row, Col } from 'reactstrap'

// import CreateProjectDialog from './CreateProjectDialog'
import { ContentPanel } from '../../components'

import UsersList         from './UsersList'

export const UsersPanel = () => <ContentPanel>
  <UsersList />
  <Container fluid>
    <Row className="color-swaths">
      {[100,200,300,400,500,600,700,800,900].map(shade =>
        <Col key={shade} className={'background-sample background-gray-'+ shade}>gray-{shade}</Col>
      )}
    </Row>
    <Row className="color-swaths">
      {['','-medium','-light'].map(shade =>
        <Col key={shade} className={'text-sample primary-text'+shade}>primary-text{shade}</Col>
      )}
    </Row>
    <Row className="color-swaths">
      {['','-medium','-light'].map(shade =>
        <Col key={shade} className={'text-sample secondary-text'+shade}>secondary-text{shade}</Col>
      )}
    </Row>
    <Row className="color-swaths">
      {['-text','-medium','-light'].map(shade =>
        <Col key={shade} className={'text-sample success'+shade}>success{shade}</Col>
      )}
    </Row>
    <Row className="color-swaths">
      {['-text','-medium','-light'].map(shade =>
        <Col key={shade} className={'text-sample warning'+shade}>warning{shade}</Col>
      )}
    </Row>
    <Row className="color-swaths">
      {['-text','-medium','-light'].map(shade =>
        <Col key={shade} className={'text-sample danger'+shade}>danger{shade}</Col>
      )}
    </Row>
    <Row className="color-swaths">
      {[33,50,60,70,80,90,100].map(shade =>
        <Col key={shade} className={'background-sample background-primary-'+ shade}>primary-{shade}</Col>
      )}
    </Row>
    <Row className="color-swaths">
      {[50,60,70,80,90,95].map(shade =>
        <Col key={shade} className={'background-sample background-secondary-'+ shade}>secondary-{shade}</Col>
      )}
    </Row>
  </Container>
</ContentPanel>
