import React, { Component } from 'react'
import cn from 'classnames'
import { inject, observer } from 'mobx-react'
import { autorun, action, observable } from 'mobx'

import { Responsive, WidthProvider } from 'react-grid-layout'

import { ValuesGraphPanel } from './ValuesGraphPanel'

import { valuesPanelState } from './state'

import './ValuesPanel.css'

const ResponsiveReactGridLayout = WidthProvider(Responsive)


@inject('scenesStore')
@observer
class ValuesPanel extends Component {
  disposer = null
  constructor(props) {
    super(props);
    this.resize = this.resize.bind(this)
  }
  lastUpdate = null
  @observable bordered = false

  componentDidMount() {
    this.addResizeListener()
    this.disposer = autorun(() => {
      if (this.props.scenesStore.lastModified !== this.lastUpdate) {
        this.lastUpdate = this.props.scenesStore.lastModified
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
        }, 1000)
      }
    })
  }
  componentWillUnmount() {
    if (this.resizeHandler) {
      clearTimeout(this.resizeHandler)
    }
    if (this.disposer) {
      this.disposer()
    }
    this.removeResizeListener()
  }

  addResizeListener() {
    if (!this.resizeHandler) {
      if (window.addEventListener)
        window.addEventListener("resize", this.resize, false);
      else
        window.attachEvent("resize", this.resize, false);
    }
  }
  removeResizeListener() {
    if (window.removeEventListener)
      window.removeEventListener("resize", this.resize);
    else
      window.detachEvent("resize", this.resize);
  }
  @action
  resize(e,repeated = false) {
    this.bordered = !repeated
    clearTimeout(this.resizeHandler)
    this.resizeHandler = setTimeout(() => {
      clearTimeout(this.resizeHandler)
      if (!repeated) {
        this.resizeHandler = setTimeout(() => {
          clearTimeout(this.resizeHandler)
          this.resize(e,true)
        }, 500)
      }
    }, 10)
  }

  render() {
    return <div>
      <ResponsiveReactGridLayout className="dashboard"
          breakpoints={{def:1200}} layouts={{def:valuesPanelState.state.layout.toJS() || []}} cols={{def:1}}
          margin={valuesPanelState.state.margin.toJS()} isDraggable={false} isResizable={false}
          rowHeight={window.innerHeight - 90} >
        {[
          <div key="graph" className={cn(this.bordered && 'bordered')}><ValuesGraphPanel /></div>,
        ]}
      </ResponsiveReactGridLayout>
    </div>
  }
}

export default ValuesPanel
