import React from 'react'
import { inject, observer } from 'mobx-react'

import { Label            } from 'sdc-i18n-react'
import { languagesStore   } from 'sdc-i18n-stores'
import { editingMode      } from 'sdc-cms-writing'


export const DetailsHeader = inject('uiStore','typesStore','editingStore')(observer(
  ({uiStore,typesStore,editingStore: store,caption,label = 'name'}) => {
    const icon = (typesStore.typesByID[store.selected.typeID] || {}).icon
    const field = label
    label = (store.selected.values || {})[label]
    if (typesStore.fields[field].localize) {
      label = (label || {})[languagesStore.selectedLanguage]
    }
    return <h4 className="details-header">
      {editingMode.isEditMode.get() && <span className="toggle float-right" onClick={editingMode.setMode('view')}><i className="fa fa-eye" /></span>}
      {editingMode.isViewMode.get() && <span className="toggle float-right" onClick={editingMode.setMode('edit')}><i className="fa fa-edit" /></span>}
      {uiStore.showIndex  && <span className="toggle float-right" onClick={uiStore.toggleIndex}><i className="fa fa-expand" /></span>}
      {!uiStore.showIndex && <span className="toggle float-right" onClick={uiStore.toggleIndex}><i className="fa fa-compress" /></span>}
      {icon && <i className={'fa fa-'+icon} />}
      <Label value={caption} label={label} />
    </h4>
  }))
