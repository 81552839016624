import   React  from 'react'
import { Link } from 'react-router-dom'

import { VisibleNotAuthenticated } from 'sdc-auth-react'
import { Button   } from 'sdc-react-basics'

import { loginURL } from '../user/login/urls'

export const LoginButton = VisibleNotAuthenticated(() => <Link to={loginURL} >
  <Button as="button" className="btn btn-outline-primary login-button" caption="home.login" />
</Link>)
