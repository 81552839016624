import React from 'react'
import qs from 'qs'
import { inject, observer } from 'mobx-react'

import { DynamicNavLink } from './DynamicNavLink'

export const ProjectNavLink = inject('projectsStore','scenesStore')(observer(({projectsStore,scenesStore,to,...props}) => {
  const params = {
    project  : projectsStore.selected.id,
    sequence : scenesStore.sequence,
  }
  return <DynamicNavLink show={projectsStore.selected.id} to={`${to}?${qs.stringify(params)}`} {...props} />
}))
