import React from 'react'
import { inject, observer } from 'mobx-react'

import Modal from 'react-modal'
import DebouncedInput from 'react-debounce-input'

import { BookAd } from 'sdc-amazon-ads'

import './dialog.scss'

@inject('projectsStore')
@observer
export class SettingsDialog extends React.Component {
  saveChanges = () => {
    this.props.saveProjectChanges()
    this.props.reselectProject()
    this.props.cancelProjectCreating()
  }
  title = 'Settings of this Project'

  updateText = field => e => {
    this.props.projectsStore.updateField(field, e.target.value)
  }
  updateNumber = field => e => {
    this.props.projectsStore.updateField(field, parseInt(e.target.value,10))
  }
  updateProject = () => this.props.onUpdateProject(this.state)

  render() {
    return <Modal isOpen={this.props.projectsStore.state.updating} contentLabel={this.title}
          onRequestClose={this.props.projectsStore.hideUpdateDialog} shouldCloseOnOverlayClick={false}
          style={{overlay : { backgroundColor: 'rgba(102,102,102,0.33)', top: 50},
            content: {border: 'solid 3px #666', left: '33%', right: '33%', top: '15%', bottom: 'none'}}}>
      <h1 style={{borderBottom: 'solid 3px #cef', paddingBottom: '6px', marginTop: 0}}>{this.title}</h1>
      <BookAd slot="modal" />
      <table className="gmcd-settings">
        <tbody>
          <tr>
            <td>Working Title</td>
            <td>
              <DebouncedInput className="form-control" type="text" placeholder="Enter project title..."
                value={this.props.projectsStore.selected.title}
                onChange={this.updateText('title')}/>
            </td>
          </tr>
          <tr>
            <td>Shared Folder</td>
            <td>/Manuscripts/{this.props.projectsStore.selected.folder}</td>
          </tr>
          <tr>
            <td>Words per Page</td>
            <td>
              <DebouncedInput minLength={3} debounceTimeout={300}
                  className="form-control" type="text" placeholder="Enter words per page..."
                  value={this.props.projectsStore.selected.wordsPerPage}
                  onChange={this.updateNumber('wordsPerPage')} />
            </td>
          </tr>
          <tr>
            <td>Words per Day</td>
            <td>
              <DebouncedInput minLength={3} debounceTimeout={300}
                  className="form-control" type="text" placeholder="Enter words per day..."
                  value={this.props.projectsStore.selected.wordsPerDay}
                  onChange={this.updateNumber('wordsPerDay')} />
            </td>
          </tr>
          <tr>
            <td colSpan="2" style={{textAlign: 'right'}}>
              <button className="btn btn-outline-secondary" onClick={this.props.projectsStore.hideUpdateDialog}>Close</button>
            </td>
          </tr>
        </tbody>
      </table>
    </Modal>
  }
}
