import React from 'react'
import { inject, observer } from 'mobx-react'

import moment from 'moment'
import cn     from 'classnames'

import * as historicalData from '../../data/historic/selectors'

export const ETA = inject('projectsStore')(observer(({projectsStore,wordsPerDay,className}) => {
  if (wordsPerDay < 1) return null
  const eta = moment().add(historicalData.togo() / wordsPerDay / 5, 'weeks')
  const onTarget = wordsPerDay === projectsStore.selected.wordsPerDay
  return <span className={cn(
    className,
    onTarget && 'on-target',
  )}>ETA {wordsPerDay}: {eta.format('DD.MM.YYYY')}</span>
}))
