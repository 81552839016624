import { autorun } from 'mobx'

import { userStore } from 'sdc-auth-user'

import { amazonType } from '../../env/constants'

import { ContentApi }    from 'sdc-cms-client'
import { StandardStore } from 'sdc-mobx-stores'


export class BooksStore extends StandardStore {

  constructor(options) {
    super(options)
    autorun(() => {
      if (userStore.user.admin) {
        this.list({})()
      } else {
        this.clearEntries()
      }
    })
  }

}

export default ({backend}) => new BooksStore({api:ContentApi({backend,typeID:amazonType})})
