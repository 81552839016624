import axios  from 'axios'

import moment from 'moment'
import 'moment/locale/de'

import { subscribe } from 'sdc-publish-subscribe'

import usersStore     from './data/users/store'
import makeBooksStore from './backoffice/books/store'

import projectsStore from './data/projects/store'
import currentStore  from './data/current/store'
import historicStore from './data/historic/store'

import { makeAdsStore     } from 'sdc-amazon-ads'
import { makeTypesStore, makeControlsStore, makeIndexStore, makeHelpStore }  from 'sdc-cms-client'
import { makeEditingStore } from 'sdc-cms-writing'
import { makeCmsLanguagesStore, makeCmsLabelsStore } from 'sdc-i18n-cms'
import { languagesStore, labelsStore } from 'sdc-i18n-stores'

import { makeWorldsStore } from './authoring/building/worlds/store'
import makeSeriesStore     from './authoring/building/series/store'
import makeStoriesStore    from './authoring/building/stories/store'
import makeIdeasStore      from './authoring/building/ideas/store'
import makeTodosStore      from './authoring/building/todos/store'
import makeAreasStore      from './authoring/building/areas/store'
import makeLocationsStore  from './authoring/building/locations/store'
import makeRacesStore      from './authoring/building/races/store'
import makeThingsStore     from './authoring/building/things/store'
import makeReferenceStore  from './authoring/building/references/store'
import makeFamiliesStore   from './authoring/building/families/store'
import makeFiguresStore    from './authoring/building/figures/store'

import scenesStore   from './authoring/story/store'
import { makeTimelineStore } from './authoring/story/timeline/store'

import { makeGenresStore   } from './backoffice/genres/store'
import { makeFormatsStore  } from './backoffice/formats/store'

import progressState from './authoring/progress/state'

import uiStore  from './data/UIStore'
import { apiBase } from './env/constants'

import { worldsType, amazonType } from './env/constants'

import { makeAuthStore } from 'sdc-auth-store'

import { userStore } from 'sdc-auth-user'
export { adSlots   } from 'sdc-amazon-ads'

export { pomodoroState } from 'sdc-pomodoro'

const cmsBase = 'https://www.headless-cms.io/api/'
const spaceID = 'wyhj5kOZZBTA1WCTsfAMbSuj23Hrf3l6'
const cms = axios.create({
  withCredentials : true,
  baseURL         : cmsBase,
})
const api = axios.create({
  withCredentials : true,
  baseURL         : apiBase,
})

export const authStore      = makeAuthStore(    {backend : api})

export const cmsLanguagesStore = makeCmsLanguagesStore({backend : cms, spaceID})
export const cmsLabelsStore    = makeCmsLabelsStore(   {backend : cms, accessKey : '0oSpKF87Aci4tsLXwC0ryzGhzvy0niin', typeID : 'Lu2yAUXjAgPS6f8Hz52pRHNHCluklafd'})
export const typesStore     = makeTypesStore(    {backend : cms})
export const editingStore   = makeEditingStore(  {backend : cms,typesStore})
export const indexStore     = makeIndexStore(    {backend : cms,typesStore,editingStore})
export const controlsStore  = makeControlsStore( {backend : cms, accessKey : 'raT6WsAmGz8GfoO3nl9RqCVpltawhnhp'})
export const helpStore      = makeHelpStore(     {backend : cms, accessKey : 'F83qvaiOjUpXeXYasGJiPpqNWiv3O544', labelsStore})

export const adsStore       = makeAdsStore(      {backend : cms, typeID : amazonType})
export const booksStore     = makeBooksStore(    {backend : cms})

export const genresStore    = makeGenresStore(   {backend : cms,typesStore,indexStore,editingStore})
export const formatsStore   = makeFormatsStore(  {backend : cms,typesStore,indexStore,editingStore})

export const worldsStore    = makeWorldsStore(   {backend : cms,typesStore,indexStore,editingStore})
export const seriesStore    = makeSeriesStore(   {backend : cms,typesStore,indexStore,editingStore,worldsStore})
export const storiesStore   = makeStoriesStore(  {backend : cms,typesStore,indexStore,editingStore,worldsStore,seriesStore})
export const familiesStore  = makeFamiliesStore( {backend : cms,typesStore,indexStore,editingStore,worldsStore,seriesStore,storiesStore})
export const ideasStore     = makeIdeasStore(    {backend : cms,typesStore,indexStore,editingStore,worldsStore,seriesStore,storiesStore})
export const todosStore     = makeTodosStore(    {backend : cms,typesStore,indexStore,editingStore,worldsStore,seriesStore,storiesStore})
export const areasStore     = makeAreasStore(    {backend : cms,typesStore,indexStore,editingStore,worldsStore,seriesStore,storiesStore,familiesStore})
export const locationsStore = makeLocationsStore({backend : cms,typesStore,indexStore,editingStore,worldsStore,seriesStore,storiesStore})
export const racesStore     = makeRacesStore(    {backend : cms,typesStore,indexStore,editingStore,worldsStore,seriesStore,storiesStore})
export const thingsStore    = makeThingsStore(   {backend : cms,typesStore,indexStore,editingStore,worldsStore,seriesStore,storiesStore})
export const referenceStore = makeReferenceStore({backend : cms,typesStore,indexStore,editingStore,worldsStore,seriesStore,storiesStore})
export const figuresStore   = makeFiguresStore(  {backend : cms,typesStore,indexStore,editingStore,worldsStore,seriesStore,storiesStore,familiesStore})

export const timelineStore  = makeTimelineStore( {scenesStore})

subscribe('selected-language', language => {
  if (language) {
    moment.locale(language)
  }
})

labelsStore.addTranslation('en.action.login','Login')
labelsStore.addTranslation('de.action.login','Anmelden')

controlsStore.setTypeID('n6MkBv0EgEjbJeoyBwmJoSuR9PKUXIVl')
helpStore.setTypeID('v2nRu1CjkICaNIoLXnUPgpyOwavzYXnN')

typesStore.setSpaceID(spaceID)

indexStore.setSpaceID(spaceID)
indexStore.setParentType(worldsType)

languagesStore.selectLanguage('de')()

export {
  labelsStore,
  languagesStore,

  usersStore,
  userStore,

  projectsStore,
  currentStore,
  historicStore,

  scenesStore,
  progressState,
  uiStore,
}
