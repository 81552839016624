import React from 'react'
import { inject, observer } from 'mobx-react'

import { Label } from 'sdc-i18n-react'

import * as options from './options'
import { mappedValues } from './selectors'

import { ChartBox } from './ChartBox'

const ownKey = 'graph'

export const ValuesGraphPanel = inject('progressState','scenesStore')(observer(
  ({progressState,scenesStore}) => {
  if (progressState.state.key !== 'all' && progressState.state.key !== ownKey) return null
  return <ChartBox max={ownKey} title={<Label value="story.values" />}
          {...options[ownKey]}
          datum={mappedValues(scenesStore.values)} />
}))
