export const toSeries = mapper => data => (data || []).map(mapper)

const extract = (value,first) => {
  if (!value) return value
  if (typeof value !== 'string') return value
  const parts = value.split(':')
  if (first && parts.length > 1) return parts[0]
  if (!first && parts.length > 1) return parts[1]
  if (!first && parts.length === 1) return parts[0]
  return undefined
}

export const toPoint = ({
  field,
  first = true,
}) => entry => ({
  x: entry.x + field / 10,
  y: extract(entry[field],first),
})
